import { HTMLProps } from 'react';

export interface ColorDotProps
  extends Omit<HTMLProps<HTMLDivElement>, 'children'> {
  color: string;
}

export const ColorDot = ({ color, ...otherProps }: ColorDotProps) => {
  return (
    <div
      className="color-dot"
      style={{
        borderRadius: '50%',
        height: '3rem',
        width: '3rem',
        border: '3px solid var(--jtjs-theme-foreground-darkened)',
        backgroundColor: color,
      }}
      {...otherProps}
    />
  );
};

export default ColorDot;
