import { Flexbox, Heading, Text } from '@jtjs/react';
import { Link } from 'react-router-dom';
import { PATHS } from '../../Router';

export interface NotFoundProps {}

export const NotFound = ({}: NotFoundProps) => {
  return (
    <Flexbox
      className="contrasted-element"
      horizontalAlignment="center"
      direction="column"
    >
      <Heading importance={2}>Are you lost?</Heading>
      <Text
        style={{
          textAlign: 'center',
        }}
      >
        Because you look a little lost. You hit like a crazy 404 or something.
        <br /> <br />
        But that's okay, we all make mistakes. Lucky for you, yours is easy to
        fix with this handy dandy button.
      </Text>
      <Link className="jtjs-button" to={PATHS.home.path}>
        Home
      </Link>
    </Flexbox>
  );
};

export default NotFound;
