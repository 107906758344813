import { Flexbox, Text } from '@jtjs/react';
import PreviewImageCard from '../../shared/PreviewImageCard';
import virtuosoScreenshot from '../../../../assets/images/virtuoso-icon.png';

export interface SoftwareProps {}

export const Software = ({}: SoftwareProps) => {
  return (
    <Flexbox>
      <PreviewImageCard
        style={{
          backgroundSize: 'contain',
          backgroundColor: 'white',
        }}
        src={virtuosoScreenshot}
        heading="Virtuoso"
        linkTo="virtuoso"
        linkText="Make Music"
      >
        <Text>
          A console application that turns plain text into .WAV files.
        </Text>
      </PreviewImageCard>
    </Flexbox>
  );
};

export default Software;
