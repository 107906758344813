import { Flexbox, Icon } from '@jtjs/react';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../Router';
import Logo from '../../Logo';

export interface DesktopNavProps {
  activePath: string;
}

export const DesktopNav = ({ activePath }: DesktopNavProps) => {
  const getActiveClass = (path: string) => {
    return activePath === path ? 'active' : undefined;
  };

  // const manuallyHandledPaths = [PATHS.home.path, PATHS.settings.path];
  // const autoHandledPaths = Object.values(PATHS).filter(
  //   (pathData) => !manuallyHandledPaths.includes(pathData.path)
  // );

  return (
    <>
      <Flexbox
        style={{
          height: '100%',
          float: 'left',
        }}
        spacing="0"
        verticalAlignment="center"
        wrap={false}
      >
        <Link id="logo-link" to={PATHS.home.path}>
          <Logo />
        </Link>
        <Link
          className={getActiveClass(PATHS.games.path)}
          to={PATHS.games.path}
        >
          Games
        </Link>
        <Link
          className={getActiveClass(PATHS.software.path)}
          to={PATHS.software.path}
        >
          Software
        </Link>
        <Link
          className={getActiveClass(PATHS.lightroom.path)}
          to={PATHS.lightroom.path}
        >
          Lightroom
        </Link>
        <Link
          className={getActiveClass(PATHS.about.path)}
          to={PATHS.about.path}
        >
          About Us
        </Link>
        <Link
          className={getActiveClass(PATHS.legal.path)}
          to={PATHS.legal.path}
        >
          Legal
        </Link>
        {/* {autoHandledPaths.map((pathData) => {
          return (
            <Link className={getActiveClass(pathData.path)} to={pathData.path}>
              {pathData.description}
            </Link>
          );
        })} */}
      </Flexbox>
      <Link
        className={getActiveClass(PATHS.settings.path)}
        to={PATHS.settings.path}
        style={{
          float: 'right',
        }}
      >
        <Icon
          icon={PATHS.settings.icon}
          iconType="solid"
          aria-label="Settings cog"
        />
      </Link>
    </>
  );
};

export default DesktopNav;
