import { Flexbox, Text } from '@jtjs/react';
import gaScreenshot from '../../../../assets/images/ga-screenshot0.jpg';
import lightroomScreenshot from '../../../../assets/images/lightroom.jpg';
import virtuosoScreenshot from '../../../../assets/images/virtuoso-screenshot0.jpg';
import { PATHS } from '../../Router';
import BackgroundHeading from '../../shared/BackgroundHeading';
import PreviewImageCard from '../../shared/PreviewImageCard';

export interface HomeProps {}

export const Home = ({}: HomeProps) => {
  return (
    <Flexbox
      direction="column"
      horizontalAlignment="center"
      spacing="0.5rem"
      wrap
      style={{
        width: '100%',
      }}
    >
      <PreviewImageCard
        heading="Latest Game"
        containerStyle={{
          minHeight: '80vh',
        }}
        linkTo={`${PATHS.games.path}/galactic-assault`}
        linkText="Galactic Assault"
        src={gaScreenshot}
      >
        <Text>
          Our latest game is Galactic Assault, an arcade space shooter with
          procedurally-generated bosses and local multiplayer!
        </Text>
      </PreviewImageCard>

      <Flexbox
        direction="row"
        verticalAlignment="center"
        horizontalAlignment="center"
        style={{
          width: '90%',
        }}
      >
        <BackgroundHeading>Explore Unlucky Cricket Games</BackgroundHeading>
      </Flexbox>

      <Flexbox
        direction="row"
        horizontalAlignment="center"
        spacing="1rem"
        style={{
          width: '100%',
        }}
      >
        <PreviewImageCard
          style={{
            backgroundPosition: 'top left',
          }}
          linkTo={PATHS.software.path}
          linkText="Learn More"
          heading="Software"
          src={virtuosoScreenshot}
        >
          <Text>
            We don't just make games, we make software, too! Pictured here is
            "sheet" music from our text-to-wav app, Virtuoso.
          </Text>
        </PreviewImageCard>

        <PreviewImageCard
          linkTo={PATHS.lightroom.path}
          linkText="Get a Show"
          heading="Lightroom"
          src={lightroomScreenshot}
        >
          <Text>
            The Lightroom is a place where you can listen to some of the music
            from our games and software while getting a show powered by realtime
            audio visualization!
          </Text>
        </PreviewImageCard>
      </Flexbox>
    </Flexbox>
  );
};

export default Home;
