import { Flexbox, formatClassName } from '@jtjs/react';
import { HTMLProps } from 'react';
import ExpandableImage, { ExpandableImageProps } from './ExpandableImage';

export interface ImageCollectionProps extends HTMLProps<HTMLPictureElement> {
  images: ExpandableImageProps[];
}

export const ImageCollection = ({
  images,
  className,
  ...otherProps
}: ImageCollectionProps) => {
  return (
    <picture
      className={formatClassName('image-collection', className)}
      {...otherProps}
    >
      <Flexbox
        direction="row"
        horizontalAlignment="left"
        verticalAlignment="top"
        wrap
      >
        {images.map((image) => {
          return (
            <div className="image-container" key={image.src}>
              <ExpandableImage {...image} />
            </div>
          );
        })}
      </Flexbox>
    </picture>
  );
};

export default ImageCollection;
