import { formatClassName, Icon, IconProps } from '@jtjs/react';

export interface SupportedDeviceBadgeProps extends IconProps {}

export const SupportedDeviceBadge = ({
  className,
  icon,
  ...otherProps
}: SupportedDeviceBadgeProps) => {
  return (
    <Icon
      aria-label={`supported on ${icon}`}
      icon={icon}
      className={formatClassName('supported-device-badge', className)}
      {...otherProps}
    />
  );
};

export default SupportedDeviceBadge;
