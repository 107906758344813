import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import SiteFooter from './shared/base/SiteFooter';
import SiteNav from './shared/base/site-nav/SiteNav';
import OverlayManager from './shared/base/overlay-manager/OverlayManager';
import { RecoilRoot } from 'recoil';

export const App = () => {
  return (
    // @ts-ignore
    <RecoilRoot>
      <BrowserRouter>
        <OverlayManager />
        <SiteNav />
        <main id="page-content">
          <Router />
        </main>
        <SiteFooter />
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
