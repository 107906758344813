import { Engine } from '@babylonjs/core';

export class EngineManager {
  private static backgroundCanvas = document.getElementById(
    'background-canvas'
  ) as HTMLCanvasElement;
  private static lastRegisteredResizeHandler: (() => void) | null = null;

  private static _engine: Engine;
  /**
   * The currently active engine.
   */
  public static get engine(): Engine {
    return this._engine;
  }
  private static set engine(val: Engine) {
    // Stop current engine before setting new one.
    this._engine?.stopRenderLoop();

    this._engine = val;
  }

  private static _canvas: HTMLCanvasElement;
  /**
   * The canvas currently being used for rendering.
   */
  public static get canvas(): HTMLCanvasElement {
    return this._canvas;
  }

  /**
   * Sets the current engine to a new one using the specified canvas for rendering. Cleans up
   * the existing engine. Does some basic setup for the engine, like setting up the handler for
   * resizing the window. Does NOT set the render loop of the new engine.
   *
   * @param canvas - (Optional, defaults to the background canvas) The canvas to use for rendering.
   * @returns - The created engine.
   */
  public static changeEngine(canvas = this.backgroundCanvas) {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    if (this.lastRegisteredResizeHandler) {
      window.removeEventListener('resize', this.lastRegisteredResizeHandler);
    }

    const resizeHandler = () => this.handleResizeEngine(canvas);

    window.addEventListener('resize', resizeHandler);
    this.lastRegisteredResizeHandler = resizeHandler;

    this._canvas = canvas;

    this.engine = new Engine(canvas);

    // Ensure the mute button provided by Babylon doesn't show up becuase it's ugly AF.
    Engine.audioEngine.useCustomUnlockedButton = true;

    return this.engine;
  }

  private static handleResizeEngine(canvas: HTMLCanvasElement) {
    if (this.engine) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    }
  }
}
