import { Flexbox, Icon, Text } from '@jtjs/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Page, PATHS } from '../../../Router';
import MobileNavLinkIcon from './MobileNavLinkIcon';

export interface MobileNavProps {
  activePath: string;
}

export const MobileNav = ({ activePath }: MobileNavProps) => {
  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    // If the pathname changes, it means we navigated somewhere else.
    // If we navigated somewhere else, we'll want to close the nav. This effectively
    // manages closing the nav when they click on a path to go to.
    setExpanded(false);
  }, [pathname]);

  const getActiveClass = (path: string) => {
    return activePath === path ? 'active' : undefined;
  };

  // If the active path isn't one of the main ones, it must be in the other paths.
  const otherPathIsActive =
    activePath !== PATHS.home.path &&
    activePath !== PATHS.games.path &&
    activePath !== PATHS.software.path;

  const mainPaths = [PATHS.home, PATHS.games, PATHS.software];
  const otherPaths = Object.values(PATHS)
    .filter((pathData) => !mainPaths.includes(pathData))
    .sort();

  const getNavLinks = (pages: Page[]) => {
    return pages.map((page) => {
      return (
        <MobileNavLinkIcon
          to={page.path}
          className={getActiveClass(page.path)}
          iconOptions={{
            icon: page.icon,
            iconType: 'solid',
          }}
          label={page.description}
          key={page.path}
        />
      );
    });
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        padding: 0,
        margin: 0,
      }}
    >
      {expanded && (
        // A full screen component that will close the nav when clicked. This
        // allows the user to click off the nav to close it.
        <div
          style={{
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            top: '0',
            left: '0',
            zIndex: '-1',
          }}
          onClick={() => {
            setExpanded(false);
          }}
        />
      )}
      <Flexbox
        className="main-paths"
        verticalAlignment="center"
        style={{
          flex: 1,
          width: '100%',
          justifyContent: 'space-evenly',
        }}
      >
        {getNavLinks(mainPaths)}
        <button
          id="more-nav-button"
          className={`mobile-nav-link-icon${
            otherPathIsActive && !expanded ? ' active' : ''
          }`}
          onClick={() => setExpanded(!expanded)}
        >
          <Flexbox
            direction="column"
            spacing="0"
            horizontalAlignment="center"
            verticalAlignment="center"
            wrap={false}
          >
            <Icon icon={expanded ? 'times' : 'ellipsis-h'} iconType="solid" />
            <Text style={{ margin: 0 }}>{expanded ? 'Less' : 'More'}</Text>
          </Flexbox>
        </button>
      </Flexbox>
      <Flexbox
        horizontalAlignment="left"
        className={`other-paths${expanded ? ' expanded' : ''}`}
        style={{
          flex: 1,
        }}
        direction="row"
        spacing="0"
        aria-expanded={expanded}
      >
        {getNavLinks(otherPaths)}
      </Flexbox>
    </div>
  );
};

export default MobileNav;
