import { Card, Heading, Text } from '@jtjs/react';
import ThemePicker from '../../shared/theme-picker/ThemePicker';

export interface SettingsProps {}

export const Settings = ({}: SettingsProps) => {
  return (
    <Card>
      <Heading importance={2}>Settings</Heading>

      <section>
        <Heading importance={3}>Theme</Heading>
        <Text>Change the theme the website uses to suit your liking!</Text>
        <ThemePicker />
      </section>
    </Card>
  );
};

export default Settings;
