import {
  Analyser,
  Angle,
  Animation,
  Axis,
  Color3,
  Color4,
  DirectionalLight,
  Engine,
  Light,
  LinesMesh,
  Mesh,
  MeshBuilder,
  Scene,
  TargetCamera,
  TransformNode,
  Vector3,
} from '@babylonjs/core';
import { random, remove } from 'lodash';
import { EngineManager } from '../managers/engine.manager';
import { SceneManager, SceneName } from '../managers/scene.manager';

interface PulserLine {
  pulserLine: LinesMesh;
  pulserLineOptions: {
    updatable: boolean;
    instance: LinesMesh | undefined;
    points: Vector3[];
  };
}

export class LightroomScene {
  private static analyser: Analyser;
  private static waveformRestValue = 128;
  // The waveform zeroes out at 128 for some reason, so to control the resting
  // position of the wave, you have to divide. The smaller the resulting number, the greater
  // the amplitude of the wave.
  private static waveformDivisor = 32;
  private static maxYDeviation = 20;

  /**
   * Creates the lightroom scene if it's not already
   *
   * @param waveformBufferSize - (Optional, defaults to 512) The buffer size to use for the waveform. MUST be a power of 2.
   * This will influence the length of the line for the waveform, as well as how accurate it is at simulating the shape of the wave.
   * @param forceCreation - (Optional, defaults to false) Whether the creation of the scene should be forced. If this is `true`,
   * the scene will be created and constructed from scratch _even if_ it's the curently active scene.
   *
   * @returns - The created scene, or null if the current scene was already the lightroom scene.
   */
  static create(waveformBufferSize = 512, forceCreation = false): Scene | null {
    if (!SceneManager.changeSceneTo(SceneName.LIGHTROOM, forceCreation)) {
      // Don't continue to construct the scene if it didn't change.
      return null;
    }

    const { scene } = SceneManager;
    const { engine, canvas } = EngineManager;

    scene.clearColor = new Color4(0, 0, 0, 1);

    const camera = new TargetCamera(
      'main camera',
      new Vector3(0, 0, -80),
      scene
    );
    camera.attachControl(canvas, true);

    this.analyser = new Analyser(scene);
    Engine.audioEngine.connectToAnalyser(this.analyser);
    this.analyser.FFT_SIZE = waveformBufferSize;

    // Build out the waveform line.
    let { points, lineOptions, waveformLines } = this.createWaveformLines();

    const leftMostPoint = points[0];
    const middlePoint = points[Math.floor(points.length / 2)];
    const rightMostPoint = points[points.length - 1];

    /**
     * The height at which the waveform line rests with no activity.
     */
    const restingHeight = middlePoint.y;

    camera.position = new Vector3(
      middlePoint.x,
      middlePoint.y,
      camera.position.z
    );

    // Build out the frequency spheres
    let { lowFreqSphere, highFreqSphere } = this.createFreqSpheres(
      leftMostPoint,
      middlePoint,
      rightMostPoint
    );

    // Build out floating cubes
    let { cubes, cubesRotationalMovementVectors } = this.createFloatingCubes(
      leftMostPoint,
      middlePoint,
      rightMostPoint
    );

    // Build out pulser lines
    const numPulserLinesPerGroup = 3;
    // How many degrees of rotational space pulsar lines in a group have between one another.
    const pulserLineRotation = 20;
    let upperRightPulserLines: PulserLine[] = [];
    let lowerLeftPulserLines: PulserLine[] = [];
    for (let i = 0; i < numPulserLinesPerGroup; i++) {
      const rotation = Angle.FromDegrees(pulserLineRotation).radians() * i;
      const upperRightLine = this.createPulserLine(
        leftMostPoint,
        middlePoint,
        rightMostPoint
      );
      const lowerLeftLine = this.createPulserLine(
        leftMostPoint,
        middlePoint,
        rightMostPoint
      );

      upperRightLine.pulserLine.rotate(Axis.Z, rotation);
      lowerLeftLine.pulserLine.rotate(Axis.Z, rotation);

      upperRightPulserLines.push(upperRightLine);
      lowerLeftPulserLines.push(lowerLeftLine);
    }

    // Group the collections of pulser lines so they're easy to move as separate groups.
    const upperRightPulser = new TransformNode('upper right pulser');
    const lowerLeftPulser = new TransformNode('lower left pulser');
    upperRightPulserLines.forEach(({ pulserLine }) => {
      pulserLine.parent = upperRightPulser;
    });
    lowerLeftPulserLines.forEach(({ pulserLine }) => {
      pulserLine.parent = lowerLeftPulser;
    });

    // Position the pulser line groups

    // Helps to determine how to get the group at a base rotation where the middle line is
    // pointing straight up.
    // Just assume both upper and lower groups have the same number of lines, so just use the upper
    // one for the length.
    const rotationAmountForMiddleLine =
      Math.floor(upperRightPulserLines.length / 2) * 20;
    upperRightPulser.position = new Vector3(
      rightMostPoint.x,
      this.maxYDeviation,
      0
    );
    upperRightPulser.rotate(
      Axis.Z,
      -Angle.FromDegrees(rotationAmountForMiddleLine + 225).radians()
    );
    lowerLeftPulser.position = new Vector3(
      leftMostPoint.x,
      -this.maxYDeviation,
      0
    );
    lowerLeftPulser.rotate(
      Axis.Z,
      -Angle.FromDegrees(rotationAmountForMiddleLine + 45).radians()
    );

    const allPulserLines = [...upperRightPulserLines, ...lowerLeftPulserLines];

    const light = new DirectionalLight('light', new Vector3(0, 0, 1), scene);
    const baseLightIntensity = 0.5;
    light.diffuse = Color3.Green();
    light.intensity = baseLightIntensity;

    waveformLines.color = light.diffuse;
    allPulserLines.forEach(
      ({ pulserLine }) => (pulserLine.color = light.diffuse)
    );

    let timeAtLastUpdate = 0;
    const timeBetweenUpdates = 50;
    scene.registerBeforeRender(() => {
      const frequencyData = this.analyser.getByteFrequencyData();

      if (Date.now() - timeAtLastUpdate >= timeBetweenUpdates) {
        // Rotate the cubes around (animations don't work well, too snappy).
        const cubeRotationSpeed = 0.03;
        cubes.forEach((cube, i) => {
          cube.rotation = new Vector3(
            cube.rotation.x +
              cubesRotationalMovementVectors[i].x * cubeRotationSpeed,
            cube.rotation.y +
              cubesRotationalMovementVectors[i].y * cubeRotationSpeed,
            cube.rotation.z +
              cubesRotationalMovementVectors[i].z * cubeRotationSpeed
          );
        });

        // Update waveform.
        const waveData = this.analyser.getByteTimeDomainData();
        let averageHeight = 0;

        for (let i = 0; i < this.analyser.FFT_SIZE; i++) {
          const data = waveData[i] / this.waveformDivisor;

          averageHeight += data;

          const point = lineOptions.points[i];
          if (point) {
            const y = data;

            const newPosition = new Vector3(point.x, y, point.z);

            lineOptions.points[i] = newPosition;
          }
        }

        averageHeight /= this.analyser.FFT_SIZE;

        lineOptions.instance = waveformLines;
        waveformLines = MeshBuilder.CreateLines('waveform lines', lineOptions);

        // Update frequency spheres
        const freqDataCount = this.analyser.getFrequencyBinCount();
        const lowFreq = frequencyData[0];
        const midFreq = frequencyData[Math.floor(freqDataCount / 2)];
        const highFreq =
          frequencyData[Math.floor(freqDataCount - freqDataCount / 3)];

        /*
         * The frequency spheres change size to represent their frequency changing. The original
         * range of values for the data is 0-255 because it's expressed as a byte, but using a potential
         * number of 255 to modify the scale results in a sphere that's too large. So, we get what part
         * of 255 the frequency is (a value between 0-1), and then we multiply that value by our
         * desired max sphere size. This allows us to express a range of 0-255 as a range
         * of 0-freqSphereMaxSize.
         */

        const lowFreqMultiplierOfMax = lowFreq / 255;
        const midFreqMultiplierOfMax = midFreq / 255;
        const highFreqMultiplierOfMax = highFreq / 255;

        const freqSphereMaxSize = 4;
        const lightMaxIntensity = 3;

        const lowFreqSample = lowFreqMultiplierOfMax * freqSphereMaxSize;
        const midFreqSample = midFreqMultiplierOfMax * lightMaxIntensity;
        const highFreqSample = highFreqMultiplierOfMax * freqSphereMaxSize;

        lowFreqSphere.scaling = new Vector3(
          1 + lowFreqSample,
          1 + lowFreqSample,
          1 + lowFreqSample
        );
        highFreqSphere.scaling = new Vector3(
          1 + highFreqSample,
          1 + highFreqSample,
          1 + highFreqSample
        );

        // Update lights
        light.intensity = baseLightIntensity + midFreqSample;
        if (light.animations.length === 0) {
          this.animateLightColor(
            light,
            new Color3(Math.random(), Math.random(), Math.random())
          );
        }

        // Update pulser lines
        const pulserEndPosition = new Vector3(
          0,
          Math.abs((averageHeight - restingHeight) * 20),
          0
        );
        allPulserLines.forEach(({ pulserLine, pulserLineOptions }, index) => {
          pulserLineOptions.points[1] = pulserEndPosition;
          pulserLineOptions.instance = pulserLine;

          pulserLine = MeshBuilder.CreateLines(
            `pulser line ${index}`,
            pulserLineOptions
          );
        });

        // pulserLine1Options.points[1] = pulserEndPosition;
        // pulserLine2Options.points[1] = pulserEndPosition;

        // pulserLine1Options.instance = pulserLine1;
        // pulserLine2Options.instance = pulserLine2;

        // pulserLine1 = MeshBuilder.CreateLines(
        //   'pulser line 1',
        //   pulserLine1Options
        // );
        // pulserLine2 = MeshBuilder.CreateLines(
        //   'pulser line 2',
        //   pulserLine2Options
        // );

        // Update line colors to match the color of the lights.
        waveformLines.color = light.diffuse;
        allPulserLines.forEach(
          ({ pulserLine }) => (pulserLine.color = light.diffuse)
        );

        timeAtLastUpdate = Date.now();
      }
    });

    engine.runRenderLoop(() => {
      scene.render();
    });

    return scene;
  }

  private static createWaveformLines() {
    const points: Vector3[] = [];

    for (let i = 0; i < this.analyser.FFT_SIZE; i++) {
      // The resting horizontal position of the waveform will be the quotient
      // of its resting value divided by our waveformDivisor (which is what's used
      // to control the Y position of the lines as the audio plays).
      points.push(
        new Vector3(i * 0.15, this.waveformRestValue / this.waveformDivisor, 0)
      );
    }

    const lineOptions: {
      points: Vector3[];
      updatable: boolean;
      instance: LinesMesh | undefined;
    } = {
      points,
      updatable: true,
      instance: undefined,
    };

    let waveformLines = MeshBuilder.CreateLines('waveform lines', lineOptions);
    waveformLines.outlineWidth = 3;

    return {
      points,
      lineOptions,
      waveformLines,
    };
  }

  private static createFreqSpheres(
    leftMostPoint: Vector3,
    middlePoint: Vector3,
    rightMostPoint: Vector3
  ) {
    const freqSphereOptions = {
      diameter: 2,
    };
    const freqSphereDistanceFromWaveform = 5;

    let lowFreqSphere = MeshBuilder.CreateSphere(
      'low freq sphere',
      freqSphereOptions
    );
    let highFreqSphere = MeshBuilder.CreateSphere(
      'high freq sphere',
      freqSphereOptions
    );

    lowFreqSphere.position = new Vector3(
      leftMostPoint.x - freqSphereDistanceFromWaveform,
      middlePoint.y,
      0
    );
    highFreqSphere.position = new Vector3(
      rightMostPoint.x + freqSphereDistanceFromWaveform,
      middlePoint.y,
      0
    );

    return {
      lowFreqSphere,
      highFreqSphere,
      freqSphereOptions,
    };
  }

  private static createFloatingCubes(
    leftMostPoint: Vector3,
    middlePoint: Vector3,
    rightMostPoint: Vector3
  ) {
    const numFloatingCubes = 50;

    const cubes: Mesh[] = [];
    const cubesRotationalMovementVectors: Vector3[] = [];
    for (let i = 0; i < numFloatingCubes; i++) {
      const size = random(0.2, 2);

      const cube = MeshBuilder.CreateBox(`floating cube ${i}`, {
        size,
      });

      const xDeviation = 10;
      const yDeviation = this.maxYDeviation;
      const zDeviation = 2;

      const isAboveLine = !!random(0, 1);

      cube.position = new Vector3(
        random(leftMostPoint.x - xDeviation, rightMostPoint.x + xDeviation),
        isAboveLine
          ? random(middlePoint.y + size, middlePoint.y + size + yDeviation)
          : random(middlePoint.y - size, middlePoint.y - size - yDeviation),
        random(middlePoint.z - zDeviation, middlePoint.z + zDeviation)
      );
      const rotationRange = [0, 2 * Math.PI];
      const startRotation = new Vector3(
        random(rotationRange[0], rotationRange[1]),
        random(rotationRange[0], rotationRange[1]),
        random(rotationRange[0], rotationRange[1])
      );

      const rotationalSpeed = new Vector3(
        random(0, 1, true),
        random(0, 1, true),
        random(0, 1, true)
      );

      cube.rotation = startRotation;

      cubes.push(cube);
      cubesRotationalMovementVectors.push(rotationalSpeed);
    }

    return {
      cubes,
      cubesRotationalMovementVectors,
    };
  }

  private static createPulserLine(
    leftMostPoint: Vector3,
    middlePoint: Vector3,
    rightMostPoint: Vector3
  ): PulserLine {
    const pulserPoints = [new Vector3(0, 0, 0), new Vector3(0, 0, 0)];

    const pulserLineOptions: {
      points: Vector3[];
      updatable: boolean;
      instance: LinesMesh | undefined;
    } = {
      updatable: true,
      instance: undefined,
      points: pulserPoints,
    };

    const pulserLine = MeshBuilder.CreateLines(
      'pulser line 1',
      pulserLineOptions
    );

    pulserLine.position = Vector3.Right();

    return {
      pulserLine,
      pulserLineOptions,
    };
  }

  /**
   * Animates the color of the specified light. When the animation completes,
   * it will be removed from the list of animations for the light.
   *
   * @param light - The light to animate
   * @param targetColor - The desired light color to animate to.
   */
  private static animateLightColor(light: Light, targetColor: Color3) {
    const frameRate = 10;

    const changeColorAnim = new Animation(
      'changeColor',
      'diffuse',
      frameRate,
      Animation.ANIMATIONTYPE_COLOR3
    );

    const keyFrames = [];
    keyFrames.push({
      frame: 0,
      value: light.diffuse,
    });
    keyFrames.push({
      frame: frameRate,
      value: targetColor,
    });

    changeColorAnim.setKeys(keyFrames);

    light.animations.push(changeColorAnim);

    SceneManager.scene.beginAnimation(light, 0, frameRate, false, 0.5, () => {
      remove(light.animations, (el) => el === changeColorAnim);
    });
  }
}
