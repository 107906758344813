import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { fullscreenState } from '../state/fullscreen.atom';
import { OverlayService } from '../services/overlay.service';
import { BackgroundScene } from '../webgl/scenes/background.scene';
import About from './pages/about/About';
import Jack from './pages/about/pages/Jack';
import Josh from './pages/about/pages/Josh';
import Sam from './pages/about/pages/Sam';
import NotFound from './pages/errors/NotFound';
import Games from './pages/games/Games';
import Blob from './pages/games/pages/Blob';
import GalacticAssault from './pages/games/pages/GalacticAssault';
import GalacticAssaultArcade from './pages/games/pages/GalacticAssaultArcade';
import { Home } from './pages/home/Home';
import Legal from './pages/legal/Legal';
import EpilepsyWarningAcknowledgement from './pages/lightroom/EpilepsyWarningAcknowledgement';
import Lightroom from './pages/lightroom/Lightroom';
import Settings from './pages/settings/Settings';
import Virtuoso from './pages/software/pages/Virtuoso';
import Software from './pages/software/Software';

export interface Page {
  path: string;
  description: string;
  icon: string;
  component: ({}: any) => JSX.Element;
  subpages?: Omit<Page, 'subpages' | 'icon'>[];
}

export const PATHS: Record<string, Page> = {
  home: {
    path: '/',
    description: 'Home',
    icon: 'home',
    component: Home,
  },
  about: {
    path: '/about',
    description: 'About Us',
    icon: 'info-circle',
    component: About,
    subpages: [
      {
        path: 'josh',
        description: 'Josh',
        component: Josh,
      },
      {
        path: 'sam',
        description: 'Sam',
        component: Sam,
      },
      {
        path: 'jack',
        description: 'Jack',
        component: Jack,
      },
    ],
  },
  games: {
    path: '/games',
    description: 'Games',
    icon: 'gamepad',
    component: Games,
    subpages: [
      {
        path: 'galactic-assault',
        description: 'Galactic Assault',
        component: GalacticAssault,
      },
      {
        path: 'galactic-assault-arcade',
        description: 'Galactic Assault ARCADE',
        component: GalacticAssaultArcade,
      },
      {
        path: 'blob',
        description: 'Blob!',
        component: Blob,
      },
    ],
  },
  legal: {
    path: '/legal',
    description: 'Legal',
    icon: 'balance-scale',
    component: Legal,
  },
  software: {
    path: '/software',
    description: 'Software',
    icon: 'code',
    component: Software,
    subpages: [
      {
        path: 'virtuoso',
        description: 'Virtuoso',
        component: Virtuoso,
      },
    ],
  },
  settings: {
    path: '/settings',
    description: 'Settings',
    icon: 'cog',
    component: Settings,
  },
  lightroom: {
    path: '/lightroom',
    description: 'Lightroom',
    icon: 'lightbulb',
    component: Lightroom,
  },
};

export interface RoutesProps {}

export const Router = ({}: RoutesProps) => {
  const { pathname } = useLocation();
  const [fullscreen, setFullscreen] = useRecoilState(fullscreenState);

  const routes: ReactNode[] = [];

  Object.values(PATHS).forEach((page) => {
    routes.push(
      <Route path={page.path} element={<page.component />} key={page.path} />
    );

    page.subpages?.forEach((subpage) => {
      const path = `${page.path}/${subpage.path}`;

      routes.push(
        <Route path={path} element={<subpage.component />} key={path} />
      );
    });
  });

  useEffect(() => {
    // On navigate, we'll want to scroll back to the top of the page so the scroll
    // from the previous page doesn't carry over onto the next.
    window.scrollTo(0, 0);

    setFullscreen(false);

    let epilepsyWarningOverlay = '';

    if (!pathname.includes(PATHS.lightroom.path)) {
      OverlayService.removeOverlay(epilepsyWarningOverlay);

      BackgroundScene.create();
    } else {
      if (
        !(
          localStorage.getItem('lightroom_epilepsyWarningAcknowledged') ===
          'true'
        )
      ) {
        epilepsyWarningOverlay = OverlayService.addOverlay({
          contents: <EpilepsyWarningAcknowledgement />,
        });
      }
    }
  }, [pathname]);

  return (
    <Routes>
      {routes}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
