import AboutCard from '../AboutCard';
import portrait from '../../../../../assets/images/sam.jpg';
import { Text } from '@jtjs/react';

export interface SamProps {}

export const Sam = ({}: SamProps) => {
  return (
    <AboutCard
      personName="Sam"
      portraitProps={{
        src: portrait,
        alt: 'A picture of Sam, an independent musician that has done work with Unlucky Cricket Games.',
      }}
    >
      <Text>
        Sam helped me out with the theme song for Blob!, which was a ton of fun
        to make. Sam graduated from the University of Michigan with a degree in
        BSE in Aerospace Engineering and a minor in physics. He currently works
        full time as a systems engineer for Northrop Grumman and part-time on
        personal game development projects, with the first game release
        tentatively set for Christmas 2019.
      </Text>
    </AboutCard>
  );
};

export default Sam;
