import {
  Color3,
  Color4,
  ParticleSystem,
  Scene,
  TargetCamera,
  Vector3,
} from '@babylonjs/core';
import { ThemeService } from '@jtjs/core-browser';
import backgroundParticleSystem from '../../../assets/gfx/particle-systems/ucg-background-particles.json';
import { EngineManager } from '../managers/engine.manager';
import { SceneManager, SceneName } from '../managers/scene.manager';

export class BackgroundScene {
  /**
   * Creates the background scene.
   *
   * @returns - The background scene, or null if the background scene was already the active scene.
   */
  static create(): Scene | null {
    if (!SceneManager.changeSceneTo(SceneName.BACKGROUND)) {
      return null;
    }

    const { scene } = SceneManager;
    const { engine, canvas } = EngineManager;

    /*
     * Set the background color of the scene to the background color for the site. Parse as a Color3 first in the event
     * the background color includes no alpha value. In the version of Babylon we're using, if a hex value is being parsed
     * into a Color4 and it has no alpha value, it apparently defaults to a transparent black.
     *
     * Parsing into a Color3 lets us get the color values, then we can take an alpha of 1 onto the Color4 we give to the
     * clearColor.
     */
    const backgroundColor = Color3.FromHexString(
      ThemeService.currentTheme.background
    );

    scene.clearColor = new Color4(
      backgroundColor.r,
      backgroundColor.g,
      backgroundColor.b,
      1
    );

    const camera = new TargetCamera(
      'BackgroundCamera',
      new Vector3(0, 0, -10),
      scene
    );
    camera.setTarget(Vector3.Zero());
    camera.attachControl(canvas, true);

    // backgroundParticleSystem.colorGradients[0];

    let ps = ParticleSystem.Parse(this.getBackgroundParticles(), scene, '');

    engine.runRenderLoop(() => {
      scene.render();
    });

    const handleThemeChange = () => {
      ps.dispose();

      ps = ParticleSystem.Parse(this.getBackgroundParticles(), scene, '');

      const backgroundColor = Color3.FromHexString(
        ThemeService.currentTheme.background
      );

      // Just in case the reference to scene is somehow broken by the time this runs,
      // we'll use the live reference right from the manager.
      SceneManager.scene.clearColor = new Color4(
        backgroundColor.r,
        backgroundColor.g,
        backgroundColor.b,
        1
      );
    };

    const handleSceneChange = () => {
      ThemeService.onChangeTheme.unsubscribe(handleThemeChange);
      SceneManager.onChangeScene.unsubscribe(handleSceneChange);
    };

    ThemeService.onChangeTheme.subscribe(handleThemeChange);
    SceneManager.onChangeScene.subscribe(handleSceneChange);

    return scene;
  }

  /**
   * Makes some minor alterations to the background particle system data to make
   * sure its colors align with the current theme.
   *
   * @returns - The modified base particle system.
   */
  private static getBackgroundParticles(): typeof backgroundParticleSystem {
    const ps = {
      ...backgroundParticleSystem,
    };

    const lightenedBackground = Color3.FromHexString(
      ThemeService.lighten(ThemeService.currentTheme.background)
    );
    const accent = Color3.FromHexString(ThemeService.currentTheme.accent);

    const colorRange = [
      [...Object.values(lightenedBackground)],
      [...Object.values(accent)],
    ];

    ps.colorGradients[0].color1 = [...colorRange[0], 1];
    ps.colorGradients[0].color2 = [...colorRange[1], 1];

    ps.colorGradients[1].color1 = [...colorRange[0], 0];
    ps.colorGradients[1].color2 = [...colorRange[1], 0];

    return ps;
  }
}
