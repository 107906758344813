import { useBreakpoint, isBreakpointSmallerThan } from '@jtjs/react';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import { useLocation } from 'react-router';
import { PATHS } from '../../../Router';
import { useRecoilValue } from 'recoil';
import { fullscreenState } from '../../../../state/fullscreen.atom';

export interface SiteNavProps {}

export const SiteNav = ({}: SiteNavProps) => {
  const fullscreen = useRecoilValue(fullscreenState);
  const breakpoint = useBreakpoint();
  const { pathname } = useLocation();

  const isMobile = isBreakpointSmallerThan(breakpoint, 'md');
  let activePath = '';
  Object.entries(PATHS).forEach(([pathId, page]) => {
    if (pathname.includes(page.path)) {
      // Intentionally don't exit after finding a match. This ensures that it checks
      // all paths. If we exited right away, the active path would always be the Home screen.
      activePath = page.path;
    }
  });

  return (
    <nav
      className={`site-nav${fullscreen ? ' hidden' : ''}`}
      id={isMobile ? 'mobile-nav' : 'desktop-nav'}
    >
      {isBreakpointSmallerThan(breakpoint, 'md') ? (
        <MobileNav activePath={activePath} />
      ) : (
        <DesktopNav activePath={activePath} />
      )}
    </nav>
  );
};

export default SiteNav;
