import { Heading, HeadingProps } from '@jtjs/react';

export interface BackgroundHeadingProps extends HeadingProps {}

/**
 * Heading component that's intended to be placed directly against the background
 * of the site. The heading is built in such a way that it should be well contrastred
 * against the background.
 */
export const BackgroundHeading = ({
  ...otherProps
}: BackgroundHeadingProps) => {
  return (
    <Heading
      importance={3}
      className="background-heading contrasted-element"
      style={{
        textAlign: 'center',
      }}
      {...otherProps}
    />
  );
};

export default BackgroundHeading;
