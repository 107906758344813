import { Heading, Link, Text } from '@jtjs/react';
import ContentCard from '../../shared/ContentCard';
import Logo from '../../shared/Logo';

export interface LegalProps {}

export const Legal = ({}: LegalProps) => {
  return (
    <div id="legal-page">
      <ContentCard flex direction="column">
        <Heading importance={3}>Terms of Use</Heading>
        <Heading importance={6}>Introduction</Heading>
        <Text>
          By accessing and using this website, or by downloading or using any of
          our products (whether through this website or one of the third-party
          distributors we use (see section 2)), you indicate that you agree to
          and accept these terms and provisions, as well as our Privacy Policy.
          When using any of the services provided on this website, you are
          subject to all of the rules and guidelines contained herein. These
          terms may be changed periodically. This agreement may be referred to
          as "agreement", "user agreement", "terms of use", "terms of serivce",
          "TOU", or "TOS". This website may be referred to as "our website",
          "this site", or "our site". Unlucky Cricket Games may be referred to
          as "us", "we", "UCG", or "Unlucky Cricket Games".
          <br />
          <br />
          ANY PARTICIPATION IN THIS SITE AND ANY USE OF ANY PRODUCT MADE BY
          UNLUCKY CRICKET GAMES CONSTITUTES ACCEPTANCE OF THIS AGREEMENT. IF YOU
          DO NOT AGREE WITH ANY OF THESE TERMS, PLEASE DO NOT USE THIS SITE OR
          ANY OF OUR PRODUCTS.
        </Text>
        <Heading importance={6}>1. Our Website</Heading>
        <Text>
          You, the user, may browse any of the pages on our website and download
          any of the products that we have expressly made available through this
          site via download buttons and/or links, provided that you agree to the
          terms of use.
          <br />
          <br />
          This website and its components are for the purpose of giving
          information related to Unlucky Cricket Games and for making available
          our products. This site is not responsible nor liable for the
          accuracy, usefulness or availability of any information transmitted or
          made available via this site. Unlucky Cricket Games is not responsible
          nor liable for any error or omissions in that information.
          <br />
          <br />
          This site and Unlucky Cricket Games, its affiliates, and its members
          are not responsible nor liable for any injury, accident, or
          harmful/negative event sustained while using this website or any of
          our products.
        </Text>
        <Heading importance={6}>2. Our Products</Heading>
        <Text>
          Our products include any of the games, software, music, and assets we
          have made. If you are unsure what those things are, please navigate to
          our Games and Software pages. Our products also bear our mark (the
          "UC" logo), and/or state that they are made "by Unlucky Cricket
          Games". All rules and guidelines contained in this agreement
          concerning our products apply to the items defined above. These rules
          and guidelines also apply to our products if you obtain them from a
          third-party service, such as a mobile app store. However, for the sake
          of clarity, let it be known that the only third-party vendors we
          approve of for our products are Steam, the Google Play Store, and the
          Apple App Store. If you obtain any of our products from a source other
          than this site or the above-listed approved vendors, we cannot and do
          not ensure its validity and/or security. In the event that you, your
          system, or device sustain damage through use of one of our products
          obtained from an unapproved vendor, you accept that you are the one
          solely responsible for the damages sustained and you release Unlucky
          Cricket Games, its affiliates, and its members from any responsibility
          and liability.
        </Text>
        <Heading importance={6}>3. Product Rights</Heading>
        <Text>
          UCG is the sole proprietor of all of our products and assets. Unlucky
          Cricket Games holds the copyright for these things. You may download
          any of the files that we enable express access to through our site via
          download buttons and links that allow you to download the files.
          However, you, the downloader and user of this site, do not own any of
          the rights to our products or any of their assets (see section 4 for
          what "assets" encompasses), as these are the property of UCG. When you
          download any of our products, you are considered to have a license for
          the product that allows you to use it. UCG reserves the right to
          revoke your license of the product at our own discretion if you
          violate this agreement.
          <br />
          <br />
          You may not redistribute any of our products or any of their assets.
          You may, however, modify a product and/or its assets ONLY for private
          use. Any redistribution that is not expressly sanctioned by UCG will
          constitute a violation of this agreement. UCG reserves the right to
          revoke any licensing given by UCG, as well as take legal action in the
          event of a violation of the TOU.
        </Text>
        <Heading importance={6}>4. Our Assets</Heading>
        <Text>
          Assets are any of the media files used in any of our products. These
          include any video files, image files, audio files, etc. packaged with
          our products. These rules and guidelines also apply to the application
          files (files with an extension of .exe, .app, etc.). These assets are
          the property of UCG, and UCG owns the rights to these assets. You, the
          user, do not own any rights to these assets. However, in our audio
          files, we will occasionally use pre-recorded loops. These loops are
          the property of their respective owners, but the music we construct
          with these loops are the property of UCG. Our assets, and therefore
          their rules and guidelines, also apply to our logo. See section 5 for
          more information on our logo.
        </Text>
        <Heading importance={6}>5. Our Logo</Heading>
        <Text>This is the UC logo: </Text>
        <Logo style={{ display: 'block', alignSelf: 'center' }} />
        <Text>
          Any use of this logo or a logo bearing its resemblance is forbidden
          unless you are using it to represent UCG. If you are using our logo to
          represent UCG or its products, you may not modify the logo, with one
          exception. You may not change its colors, nor its shape, nor any other
          aspect of the image, barring the exception. This is the exception: You
          may scale the image to suit your needs, though the resulting
          resolution of the scaled logo must have an aspect ratio of 1.6:1, and
          be easily distinguishable. You may not use our logo to represent our
          product if you do not maintain this aspect ratio.
          <br />
          <br />
          You may not file trademark applications for or claim trademark rights
          to the UC logo or any derivatives thereof. Unlucky Cricket Games
          retains all trademark rights to the UC logo.
        </Text>
      </ContentCard>
      <ContentCard>
        <Heading importance={5}>Privacy Policy</Heading>
        <Text>
          We like privacy. We don't like people knowing everything about our
          personal selves, and we think you deserve the same courtesy. However,
          some channels we use do gather some information. Look below to see
          more.
          <br />
          <br />
          On our mobile games, the Google Play Store tracks basic system
          information about your phone (like your OS version), though nothing it
          gathers and sends to us is personally identifiable. Look at{' '}
          <Link href="https://play.google.com/about/play-terms/index.html">
            Google's Terms of Service
          </Link>{' '}
          if you're curious (the privacy bit's in there). We use the information
          gathered to aid in bug-fixing and to give us statistical data on our
          user base. We do not sell your information, nor do we use it for
          anything other than the above-listed purposes.
          <br />
          <br />
          Our PC/Mac games may also gather information about your system, though
          this is done in an anonymous manner and no data is personally
          identifiable. This information is not sold under any circumstances,
          and serves only to allow us to assess a product's performance. This
          information is also not gathered passively, but instead as the result
          of an event. An example of an event causing this information to be
          gathered would be on a crash of one of our games. You, the user, will
          be prompted whether you would like to send us the crash information.
          If you choose not to, we will not see the information, though a data
          log containing system information may still be generated locally on
          your machine. You may do whatever you like with this data, though you
          may not modify the data and then share the modified data with Unlucky
          Cricket Games. The data is not shared with Unlucky Cricket Games
          unless you expressly choose to share it.
          <br />
          <br />
          <em>
            This policy is subject to change, though our respect for your
            privacy will always endure. Please check back regularly.
          </em>
        </Text>
      </ContentCard>
      <ContentCard>
        <Heading importance={5}>Attributions</Heading>
        <Text>
          The Android robot is reproduced or modified from work created and
          shared by Google and used according to terms described in the{' '}
          <Link href="https://creativecommons.org/licenses/by/3.0/">
            Creative Commons
          </Link>{' '}
          3.0 Attribution License.
        </Text>
      </ContentCard>
    </div>
  );
};

export default Legal;
