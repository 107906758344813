import { ThemeService } from '@jtjs/core-browser';
import {
  BackgroundImageCard,
  BackgroundImageCardProps,
  Heading,
} from '@jtjs/react';
import { ComponentType, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface PreviewImageCardProps
  extends Omit<BackgroundImageCardProps, 'children'> {
  /**
   * The heading to use for the card. This will be displayed as a heading with importance
   * of 2. Note that this shouldn't be specified if `headingComponent` is specified.
   */
  heading?: string;
  /**
   * The component to use for the header of the card. Note that this shouldn't specified
   * if `heading` is specified.
   */
  headingComponent?: ComponentType;
  linkTo?: string;
  linkText?: string;
  containerStyle?: React.CSSProperties;
  children?: ReactNode | ReactNode[];
}

/**
 * An ImageCard that shows a heading and a button. When hovered/focused on medium+ screens,
 * the children will appear at the bottom with a shadow behind them for contrast. It's intended
 * that the children are text that further explain the card. The card acts as a link, and clicking
 * on it will take the user to the link specified by the `linkTo` prop.
 */
export const PreviewImageCard = ({
  heading,
  headingComponent,
  children,
  linkTo,
  linkText,
  containerStyle,
  ...otherProps
}: PreviewImageCardProps) => {
  let HeadingComponent: ComponentType = () => <></>;
  if (heading) {
    HeadingComponent = () => (
      <Heading className="contrasted-element" importance={2}>
        {heading}
      </Heading>
    );
  } else if (headingComponent) {
    HeadingComponent = headingComponent;
  }

  const hasLink = !!linkTo;

  // Determine what the card is wrapped in based on whether a link was provided.
  const Wrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
    const className = 'preview-image-card-container';

    return hasLink ? (
      <Link to={linkTo ?? ''} className={className} style={containerStyle}>
        {children}
      </Link>
    ) : (
      <div className={className} style={containerStyle}>
        {children}
      </div>
    );
  };

  return (
    <Wrapper>
      <BackgroundImageCard
        className={`preview-image-card${hasLink ? ' with-link' : ''}`}
        flex
        direction="column"
        horizontalAlignment="center"
        verticalAlignment="center"
        innerShadow
        innerShadowOptions={{
          length: '50%',
          color: ThemeService.currentTheme.foreground,
        }}
        {...otherProps}
      >
        <HeadingComponent />
        {linkText && <span className="jtjs-button">{linkText}</span>}
        <div className="details-container">{children}</div>
      </BackgroundImageCard>
    </Wrapper>
  );
};

export default PreviewImageCard;
