import { Flexbox, formatClassName, Heading, IconProps } from '@jtjs/react';
import ContentCard, { ContentCardProps } from './ContentCard';
import SupportedDeviceBadge from './SupportedDeviceBadge';

export interface ProductCardProps extends ContentCardProps {
  heading: string;
  supportedDevicesIcons?: IconProps[];
}

export const ProductCard = ({
  heading,
  supportedDevicesIcons,
  className,
  children,
  ...otherProps
}: ProductCardProps) => {
  return (
    <ContentCard
      className={formatClassName('product-card', className)}
      {...otherProps}
    >
      <Flexbox horizontalAlignment="center" direction="column">
        <Heading importance={2}>{heading}</Heading>
        {supportedDevicesIcons?.map((supportedDeviceIcon) => {
          return (
            <SupportedDeviceBadge
              key={supportedDeviceIcon.icon}
              {...supportedDeviceIcon}
            />
          );
        })}
      </Flexbox>
      {children}
    </ContentCard>
  );
};

export default ProductCard;
