import {
  Button,
  Flexbox,
  FlexboxProps,
  formatClassName,
  Icon,
} from '@jtjs/react';
import {
  OverlayData,
  OverlayService,
} from '../../../../services/overlay.service';

export interface OverlayProps extends FlexboxProps {
  overlayData: OverlayData;
}

export const Overlay = ({
  className,
  overlayData,
  children,
  ...otherProps
}: OverlayProps) => {
  const removeThisOverlay = () => {
    OverlayService.removeOverlay(overlayData.id ?? '');
  };

  return (
    <Flexbox
      className={formatClassName('overlay', className)}
      id={overlayData.id}
      horizontalAlignment="center"
      verticalAlignment="center"
      onClick={(event) => {
        if ((event.target as HTMLElement)?.id === overlayData.id) {
          removeThisOverlay();
        }
      }}
      {...otherProps}
    >
      <Button
        className="close-button"
        aria-label="close overlay"
        onClick={removeThisOverlay}
      >
        <Icon icon="times" iconType="solid" />
      </Button>
      {children}
    </Flexbox>
  );
};

export default Overlay;
