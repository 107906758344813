import { HTMLProps } from 'react';

export interface LogoProps extends HTMLProps<HTMLDivElement> {}

export const Logo = ({ ...otherProps }: LogoProps) => {
  return (
    <div className="ucg-logo" {...otherProps}>
      <svg viewBox="0 0 640 400">
        <path
          id="Selection"
          className="logo-image"
          stroke="black"
          strokeWidth="1"
          d="M 207.00,95.00
           C 208.46,100.54 205.18,102.36 203.13,107.00
             201.46,110.78 201.76,122.14 191.00,126.00
             191.00,126.00 191.00,225.00 191.00,225.00
             191.00,225.00 191.00,253.00 191.00,253.00
             190.99,256.97 191.44,258.69 189.00,262.00
             192.68,268.61 189.79,270.42 186.09,276.00
             186.09,276.00 181.22,283.83 181.22,283.83
             181.22,283.83 171.52,298.91 171.52,298.91
             171.52,298.91 167.68,303.28 167.68,303.28
             165.97,305.46 165.30,307.50 162.87,309.15
             160.52,310.74 156.79,311.12 154.00,311.59
             154.00,311.59 134.00,314.00 134.00,314.00
             130.90,314.03 121.70,314.51 119.42,312.98
             117.11,311.44 116.76,308.52 116.48,306.00
             116.48,306.00 115.00,288.00 115.00,288.00
             115.00,288.00 114.00,269.00 114.00,269.00
             114.00,269.00 119.83,201.00 119.83,201.00
             119.83,201.00 121.00,146.00 121.00,146.00
             121.02,135.54 125.90,127.44 124.89,118.00
             124.35,112.86 122.35,112.43 119.88,108.72
             118.29,106.33 110.34,92.02 110.00,90.00
             100.48,82.15 100.00,77.14 93.48,69.01
             93.48,69.01 83.37,57.26 83.37,57.26
             81.26,55.05 78.54,53.67 77.01,50.95
             76.09,49.33 75.58,46.82 75.00,45.00
             75.00,45.00 72.00,46.00 72.00,46.00
             68.03,39.32 66.78,41.04 62.32,36.66
             56.92,31.36 55.55,25.38 47.00,24.00
             46.97,34.70 45.47,33.97 43.52,43.00
             43.52,43.00 41.24,60.00 41.24,60.00
             41.24,60.00 31.62,85.00 31.62,85.00
             31.62,85.00 24.35,112.87 24.35,112.87
             22.90,115.84 20.97,116.27 19.73,119.13
             19.73,119.13 18.20,125.00 18.20,125.00
             18.20,125.00 10.86,144.00 10.86,144.00
             7.65,151.85 4.10,155.57 8.00,164.00
             12.13,161.89 12.66,161.58 16.00,165.00
             16.00,165.00 18.00,164.00 18.00,164.00
             18.00,164.00 30.50,182.04 30.50,182.04
             36.63,192.13 41.38,203.90 45.34,215.00
             47.70,221.60 50.46,226.44 58.00,227.00
             57.67,217.59 54.58,220.82 51.07,212.00
             43.18,192.20 42.09,186.23 29.17,167.99
             27.41,165.50 24.18,161.50 21.83,159.63
             19.13,157.49 15.22,156.71 14.74,152.90
             13.77,145.20 19.60,137.48 22.68,131.00
             22.68,131.00 30.31,112.00 30.31,112.00
             30.31,112.00 38.24,98.00 38.24,98.00
             38.24,98.00 41.53,89.00 41.53,89.00
             45.83,79.34 50.40,81.32 49.00,68.00
             49.00,68.00 55.46,65.77 55.46,65.77
             62.02,64.15 68.96,66.34 69.00,74.00
             69.00,74.00 73.00,76.00 73.00,76.00
             73.00,76.00 72.00,78.00 72.00,78.00
             81.88,83.83 79.46,87.20 83.45,91.47
             87.23,95.53 91.31,95.02 91.00,102.00
             91.00,102.00 99.00,113.00 99.00,113.00
             99.00,113.00 95.00,114.00 95.00,114.00
             95.00,114.00 96.00,118.00 96.00,118.00
             100.86,117.13 100.96,117.03 105.00,120.00
             101.39,119.66 94.48,118.82 91.42,120.99
             87.72,123.61 87.15,131.88 87.59,136.00
             87.59,136.00 88.17,148.00 88.17,148.00
             88.17,148.00 90.00,170.00 90.00,170.00
             90.00,170.00 91.83,191.00 91.83,191.00
             91.83,191.00 93.83,216.00 93.83,216.00
             93.83,216.00 101.27,287.00 101.27,287.00
             101.27,287.00 109.00,332.00 109.00,332.00
             109.00,332.00 125.00,330.00 125.00,330.00
             125.00,330.00 151.00,330.00 151.00,330.00
             144.41,339.43 144.90,342.93 139.86,353.00
             138.44,355.84 137.13,359.56 134.78,361.69
             130.08,365.92 114.33,362.72 116.24,374.98
             117.06,380.28 121.35,381.26 126.00,380.89
             144.04,379.47 144.75,363.85 149.48,356.00
             153.70,348.98 160.95,342.11 168.00,338.00
             166.80,334.56 167.38,334.36 170.00,332.00
             170.00,332.00 169.00,335.00 169.00,335.00
             174.98,337.47 175.39,337.11 180.00,333.00
             180.00,333.00 179.00,334.00 179.00,334.00
             179.00,334.00 180.00,336.00 180.00,336.00
             189.84,332.31 198.68,340.37 205.87,336.99
             210.21,334.95 209.75,327.14 210.08,323.00
             210.08,323.00 215.00,285.00 215.00,285.00
             215.00,285.00 222.00,285.88 222.00,285.88
             222.00,285.88 232.00,290.00 232.00,290.00
             232.00,290.00 226.00,292.00 226.00,292.00
             226.00,292.00 229.00,296.00 229.00,296.00
             229.00,296.00 235.00,294.00 235.00,294.00
             235.00,294.00 239.00,300.00 239.00,300.00
             239.00,300.00 235.00,300.00 235.00,300.00
             235.00,300.00 238.00,304.00 238.00,304.00
             238.00,304.00 245.00,302.00 245.00,302.00
             245.00,302.00 237.47,339.00 237.47,339.00
             236.75,341.82 233.13,348.61 237.47,350.36
             240.16,351.55 244.22,348.67 249.00,348.09
             249.00,348.09 265.00,345.18 265.00,345.18
             265.00,345.18 271.91,344.38 271.91,344.38
             275.10,343.18 276.60,339.83 280.01,338.01
             282.42,336.72 291.90,335.46 295.00,335.00
             295.00,335.00 305.00,335.00 305.00,335.00
             316.43,333.74 339.73,328.93 351.00,325.85
             355.35,324.66 366.88,321.74 368.12,316.99
             370.15,309.19 357.27,312.39 354.00,312.84
             354.00,312.84 343.00,315.15 343.00,315.15
             343.00,315.15 307.00,312.00 307.00,312.00
             307.00,312.00 297.00,312.00 297.00,312.00
             292.11,311.99 282.94,311.59 279.64,307.44
             277.81,305.16 278.96,300.01 279.00,297.00
             279.00,297.00 277.91,272.00 277.91,272.00
             277.91,272.00 272.09,205.00 272.09,205.00
             272.09,205.00 268.00,161.00 268.00,161.00
             268.00,161.00 319.00,142.42 319.00,142.42
             319.00,142.42 337.00,137.62 337.00,137.62
             345.90,136.01 344.34,138.52 354.00,134.00
             353.66,132.51 353.59,131.06 352.26,129.99
             350.71,128.74 347.84,129.08 346.00,129.29
             346.00,129.29 332.00,130.09 332.00,130.09
             332.00,130.09 309.00,132.00 309.00,132.00
             309.00,132.00 288.00,132.00 288.00,132.00
             284.55,131.96 269.88,130.08 267.04,128.97
             259.31,125.94 263.83,119.60 256.00,117.00
             253.08,123.90 254.00,130.61 254.00,138.00
             254.00,138.00 254.00,174.00 254.00,174.00
             254.00,174.00 253.09,186.00 253.09,186.00
             253.09,186.00 251.91,207.00 251.91,207.00
             251.91,207.00 251.03,216.00 251.03,216.00
             251.03,216.00 251.90,227.00 251.90,227.00
             251.74,230.64 250.62,234.11 251.13,237.91
             251.64,241.79 253.79,242.71 253.44,246.01
             253.44,246.01 250.41,254.00 250.41,254.00
             250.41,254.00 248.00,277.00 248.00,277.00
             236.44,277.28 237.18,272.68 229.00,272.00
             226.15,267.41 222.91,268.02 218.00,268.00
             218.00,268.00 220.00,266.00 220.00,266.00
             220.00,266.00 215.00,265.00 215.00,265.00
             215.00,265.00 215.00,261.00 215.00,261.00
             215.00,261.00 216.00,264.00 216.00,264.00
             216.00,264.00 219.00,258.00 219.00,258.00
             215.54,256.72 216.63,253.08 216.97,250.00
             216.97,250.00 216.97,239.00 216.97,239.00
             216.97,239.00 218.00,229.00 218.00,229.00
             218.00,229.00 220.00,192.00 220.00,192.00
             220.00,192.00 221.00,177.00 221.00,177.00
             221.00,177.00 222.00,161.00 222.00,161.00
             222.00,161.00 224.00,120.00 224.00,120.00
             224.00,120.00 221.00,120.00 221.00,120.00
             221.00,120.00 224.79,102.99 224.79,102.99
             224.79,102.99 228.32,95.00 228.32,95.00
             229.47,91.68 228.99,89.82 231.38,85.00
             234.77,78.14 239.31,74.26 241.73,70.00
             241.73,70.00 248.89,56.10 248.89,56.10
             248.89,56.10 261.01,42.86 261.01,42.86
             262.93,41.01 266.43,38.77 265.00,36.00
             274.90,31.59 275.58,26.98 283.00,21.31
             287.19,18.11 301.64,13.76 307.00,14.06
             316.18,14.56 321.02,20.32 328.00,24.27
             333.08,27.14 335.42,26.16 338.79,29.14
             341.51,31.55 341.10,33.37 344.24,36.35
             349.88,41.72 356.34,43.99 360.27,47.53
             360.27,47.53 374.37,63.64 374.37,63.64
             374.37,63.64 393.00,78.67 393.00,78.67
             393.00,78.67 401.00,83.02 401.00,83.02
             408.05,87.26 405.84,88.69 408.58,91.08
             411.69,93.79 418.96,94.91 423.00,95.00
             422.33,86.97 419.13,90.07 414.30,86.19
             411.92,84.28 412.00,82.42 408.86,79.90
             408.86,79.90 398.00,73.67 398.00,73.67
             398.00,73.67 380.22,59.41 380.22,59.41
             380.22,59.41 364.00,41.30 364.00,41.30
             358.07,36.55 346.14,33.86 345.00,25.00
             345.00,25.00 344.00,24.00 344.00,24.00
             344.00,24.00 342.00,25.00 342.00,25.00
             342.00,25.00 331.00,18.29 331.00,18.29
             331.00,18.29 317.00,10.25 317.00,10.25
             317.00,10.25 292.00,7.00 292.00,7.00
             292.00,7.00 280.00,7.60 280.00,7.60
             280.00,7.60 259.00,26.91 259.00,26.91
             259.00,26.91 250.30,34.59 250.30,34.59
             250.30,34.59 246.46,39.60 246.46,39.60
             246.46,39.60 240.72,44.27 240.72,44.27
             240.72,44.27 234.28,57.79 234.28,57.79
             234.28,57.79 229.54,62.36 229.54,62.36
             229.54,62.36 226.55,66.41 226.55,66.41
             226.55,66.41 212.71,82.00 212.71,82.00
             212.71,82.00 207.00,95.00 207.00,95.00 Z
           M 265.00,36.00
           C 265.00,36.00 265.00,37.00 265.00,37.00
             265.00,37.00 264.00,36.00 264.00,36.00
             264.00,36.00 265.00,36.00 265.00,36.00 Z
           M 56.00,62.00
           C 56.00,62.00 50.00,60.00 50.00,60.00
             50.00,60.00 52.00,58.00 52.00,58.00
             52.00,58.00 50.00,54.00 50.00,54.00
             54.55,55.63 56.18,56.99 56.00,62.00 Z
           M 50.00,60.00
           C 50.00,60.00 50.00,61.00 50.00,61.00
             50.00,61.00 49.00,60.00 49.00,60.00
             49.00,60.00 50.00,60.00 50.00,60.00 Z
           M 54.00,62.00
           C 52.87,64.71 52.87,64.45 50.00,65.00
             50.00,65.00 51.00,62.00 51.00,62.00
             51.00,62.00 54.00,62.00 54.00,62.00 Z
           M 370.00,132.00
           C 370.00,132.00 364.00,131.00 364.00,131.00
             364.00,131.00 366.00,137.00 366.00,137.00
             366.00,137.00 373.00,134.00 373.00,134.00
             373.00,134.00 373.00,140.00 373.00,140.00
             384.63,137.75 391.54,149.76 402.00,149.00
             403.29,152.42 411.68,159.12 415.17,160.42
             416.55,160.93 417.59,160.89 419.00,161.00
             421.14,168.52 426.40,172.30 434.00,173.00
             434.00,173.00 436.00,179.00 436.00,179.00
             439.51,179.83 441.51,181.76 444.00,179.00
             444.00,179.00 454.00,184.00 454.00,184.00
             454.00,184.00 453.00,189.00 453.00,189.00
             453.00,189.00 459.00,187.00 459.00,187.00
             459.00,187.00 461.00,192.00 461.00,192.00
             461.00,192.00 466.00,190.00 466.00,190.00
             466.00,190.00 465.00,193.00 465.00,193.00
             465.00,193.00 468.00,188.00 468.00,188.00
             468.00,188.00 474.00,190.00 474.00,190.00
             474.00,190.00 470.00,191.00 470.00,191.00
             470.00,191.00 473.00,192.00 473.00,192.00
             473.00,192.00 472.00,197.00 472.00,197.00
             479.83,194.86 480.41,191.62 485.00,196.00
             485.00,196.00 481.00,196.00 481.00,196.00
             481.00,196.00 489.00,199.00 489.00,199.00
             489.00,199.00 489.00,195.00 489.00,195.00
             489.00,195.00 490.00,198.00 490.00,198.00
             491.56,198.10 493.60,197.96 493.00,200.00
             493.00,200.00 496.00,201.00 496.00,201.00
             496.00,201.00 492.00,202.00 492.00,202.00
             492.00,202.00 495.00,206.00 495.00,206.00
             495.00,206.00 499.00,206.00 499.00,206.00
             499.00,206.00 499.00,203.00 499.00,203.00
             510.22,203.66 503.86,208.30 512.00,211.00
             512.00,211.00 513.00,207.00 513.00,207.00
             515.49,208.83 522.37,214.65 521.51,218.17
             521.18,219.53 518.11,222.05 517.00,223.00
             517.00,223.00 521.00,224.00 521.00,224.00
             521.00,224.00 521.00,225.00 521.00,225.00
             521.00,225.00 519.00,226.00 519.00,226.00
             519.00,226.00 527.00,228.00 527.00,228.00
             527.00,228.00 527.00,231.00 527.00,231.00
             527.00,231.00 532.00,229.00 532.00,229.00
             532.00,229.00 533.00,234.00 533.00,234.00
             533.00,234.00 534.00,231.00 534.00,231.00
             537.74,236.88 541.19,240.82 548.00,243.00
             548.00,243.00 548.00,245.00 548.00,245.00
             548.00,245.00 537.00,250.00 537.00,250.00
             537.38,251.30 537.96,252.57 537.79,253.98
             537.36,257.64 531.49,261.50 528.00,261.51
             526.53,261.51 523.56,260.44 522.00,260.00
             522.00,260.00 523.00,258.00 523.00,258.00
             523.00,258.00 520.00,258.00 520.00,258.00
             516.15,249.67 509.96,251.26 514.00,243.00
             514.00,243.00 509.00,246.00 509.00,246.00
             509.00,246.00 510.00,240.00 510.00,240.00
             510.00,240.00 507.00,238.00 507.00,238.00
             507.00,238.00 504.00,239.00 504.00,239.00
             502.32,234.81 501.28,235.14 497.00,235.00
             497.00,235.00 498.00,232.00 498.00,232.00
             495.15,228.70 495.44,227.06 493.32,224.37
             493.32,224.37 479.79,209.37 479.79,209.37
             479.79,209.37 472.00,203.00 472.00,203.00
             467.12,198.62 460.03,193.29 453.00,194.24
             450.60,194.56 449.16,195.46 447.00,196.22
             433.83,200.82 440.40,200.08 431.72,206.10
             431.72,206.10 415.00,213.00 415.00,213.00
             412.44,217.19 407.87,218.45 404.17,221.55
             397.43,227.20 396.78,233.28 387.00,233.94
             384.00,234.14 381.63,233.89 379.01,232.27
             375.18,229.88 370.63,223.04 365.57,227.89
             363.94,229.45 363.99,231.94 363.45,234.00
             362.80,236.50 361.69,238.37 361.54,241.00
             361.24,246.29 363.81,251.50 359.35,258.00
             353.70,266.23 345.98,269.36 339.00,275.19
             339.00,275.19 312.00,301.00 312.00,301.00
             312.00,301.00 322.00,306.00 322.00,306.00
             322.00,306.00 322.00,301.00 322.00,301.00
             327.85,300.65 329.70,299.98 331.00,294.00
             337.45,292.95 341.94,286.54 347.00,282.42
             348.58,281.14 351.77,278.34 353.85,278.33
             356.70,278.33 356.96,281.92 356.96,284.00
             356.97,291.00 352.50,292.57 353.32,297.98
             353.97,302.30 358.09,304.33 361.59,301.40
             363.39,299.89 364.25,297.16 365.00,295.00
             365.00,295.00 367.00,288.00 367.00,288.00
             367.00,288.00 364.00,287.00 364.00,287.00
             366.48,279.14 366.34,278.59 372.43,272.17
             377.07,267.27 380.52,266.19 384.00,260.00
             384.00,260.00 386.00,262.00 386.00,262.00
             394.95,259.08 393.55,266.83 398.18,271.00
             408.78,280.56 406.10,281.20 413.82,291.00
             418.67,297.16 425.16,302.74 426.00,311.00
             435.71,313.88 438.20,322.02 443.91,328.77
             448.89,334.65 454.15,334.93 451.00,343.00
             458.31,343.60 458.12,345.90 454.00,351.00
             454.00,351.00 455.00,356.00 455.00,356.00
             455.00,356.00 456.00,357.00 456.00,357.00
             459.30,356.28 461.90,354.93 463.00,359.00
             463.00,359.00 467.00,363.00 467.00,363.00
             460.70,365.27 455.45,372.63 466.00,374.00
             466.00,374.00 466.00,367.15 466.00,367.15
             466.00,367.15 470.00,367.15 470.00,367.15
             473.81,375.98 479.78,370.18 486.00,367.15
             488.74,366.23 493.54,364.90 495.63,363.28
             499.48,360.29 498.23,358.58 504.00,358.00
             503.13,350.53 499.00,348.83 492.00,350.79
             490.13,351.32 485.58,353.79 484.23,351.44
             483.17,349.57 486.23,344.10 487.00,342.00
             491.57,341.06 493.44,337.68 496.00,334.00
             477.52,334.00 484.36,335.86 476.00,337.00
             476.00,337.00 471.00,343.00 471.00,343.00
             471.00,343.00 474.00,338.00 474.00,338.00
             474.00,338.00 470.00,338.00 470.00,338.00
             471.65,326.17 482.36,326.02 479.00,316.00
             479.00,316.00 471.00,317.00 471.00,317.00
             470.59,321.10 464.95,328.81 460.16,327.09
             456.96,325.94 452.85,321.06 450.45,318.68
             450.45,318.68 438.00,307.00 438.00,307.00
             444.60,306.95 451.39,305.09 455.00,312.00
             455.00,312.00 457.00,309.00 457.00,309.00
             457.00,309.00 462.00,310.00 462.00,310.00
             462.00,310.00 464.00,306.00 464.00,306.00
             464.00,306.00 464.00,310.00 464.00,310.00
             464.00,310.00 469.00,309.00 469.00,309.00
             469.00,309.00 468.00,306.00 468.00,306.00
             468.00,306.00 472.00,306.00 472.00,306.00
             472.00,306.00 471.00,309.00 471.00,309.00
             471.00,309.00 480.00,309.00 480.00,309.00
             480.00,309.00 473.00,308.00 473.00,308.00
             476.12,301.05 478.74,300.13 486.00,298.12
             488.41,297.62 490.91,296.57 493.00,298.12
             493.00,298.12 496.00,296.00 496.00,296.00
             497.39,291.09 501.58,291.11 506.00,291.00
             506.00,291.00 503.00,290.00 503.00,290.00
             503.00,290.00 510.00,289.00 510.00,289.00
             510.00,289.00 509.00,287.00 509.00,287.00
             509.00,287.00 525.00,282.00 525.00,282.00
             525.00,282.00 523.00,291.00 523.00,291.00
             523.00,291.00 520.00,293.00 520.00,293.00
             520.00,293.00 520.00,299.00 520.00,299.00
             520.00,299.00 529.00,297.00 529.00,297.00
             529.00,297.00 528.00,293.00 528.00,293.00
             528.00,293.00 534.00,299.00 534.00,299.00
             534.00,299.00 544.00,298.00 544.00,298.00
             545.90,306.97 539.61,308.61 546.00,311.00
             546.00,311.00 543.00,316.00 543.00,316.00
             543.00,316.00 548.00,317.00 548.00,317.00
             548.00,317.00 547.00,320.00 547.00,320.00
             547.00,320.00 551.00,324.00 551.00,324.00
             551.00,324.00 545.00,324.00 545.00,324.00
             545.00,324.00 551.00,327.00 551.00,327.00
             552.66,327.34 554.41,327.73 552.00,329.00
             557.65,335.10 562.69,334.22 567.41,339.13
             569.39,341.18 572.19,343.50 570.00,346.00
             570.00,346.00 572.00,350.00 572.00,350.00
             572.00,350.00 583.00,353.00 583.00,353.00
             583.00,353.00 582.00,355.00 582.00,355.00
             582.00,355.00 581.00,355.00 581.00,355.00
             581.00,355.00 591.00,360.00 591.00,360.00
             592.02,356.89 593.92,357.01 597.00,356.88
             603.99,356.56 617.58,358.70 615.76,347.02
             614.46,338.69 604.91,341.68 597.00,340.95
             594.65,340.73 590.27,339.89 593.00,337.00
             593.00,337.00 591.00,335.00 591.00,335.00
             596.86,333.66 606.67,330.92 607.89,324.00
             607.89,324.00 607.00,314.00 607.00,314.00
             607.00,314.00 595.99,322.47 595.99,322.47
             595.99,322.47 585.00,328.00 585.00,328.00
             587.10,322.52 588.35,320.65 586.00,315.00
             586.00,315.00 582.00,317.00 582.00,317.00
             581.24,323.20 577.90,322.84 573.00,321.00
             575.30,308.23 582.92,304.57 590.00,294.00
             582.86,290.94 580.98,295.20 575.00,299.27
             571.87,301.41 569.65,303.55 567.00,300.00
             567.00,300.00 563.00,306.00 563.00,306.00
             563.00,306.00 561.00,306.00 561.00,306.00
             557.99,298.40 554.95,294.12 549.73,287.95
             548.09,286.02 543.54,281.90 543.03,279.91
             542.20,276.63 544.65,275.39 547.00,274.00
             547.72,271.06 554.63,261.29 557.10,259.75
             558.71,258.75 561.14,258.43 563.00,258.00
             563.00,258.00 561.00,253.00 561.00,253.00
             561.00,253.00 563.00,255.00 563.00,255.00
             563.00,255.00 564.00,255.00 564.00,255.00
             565.67,251.02 567.56,248.89 565.00,245.00
             566.37,242.75 566.37,243.25 565.00,241.00
             568.34,235.82 557.42,228.49 555.86,225.83
             554.91,224.20 555.22,223.91 555.00,222.76
             553.88,222.91 550.68,222.89 549.87,222.76
             549.87,222.76 544.23,216.63 544.23,216.63
             544.23,216.63 526.42,195.22 526.42,195.22
             520.90,189.86 515.28,190.29 509.04,187.87
             509.04,187.87 494.00,178.71 494.00,178.71
             491.08,177.47 486.46,177.55 483.00,177.00
             483.00,177.00 484.00,174.00 484.00,174.00
             484.00,174.00 480.00,175.00 480.00,175.00
             480.00,175.00 481.00,171.00 481.00,171.00
             473.50,171.23 471.19,174.27 465.00,168.00
             460.59,169.09 453.44,165.48 449.00,164.00
             449.00,164.00 450.00,160.00 450.00,160.00
             444.25,158.63 438.83,156.45 433.00,159.00
             433.00,159.00 433.00,154.00 433.00,154.00
             433.00,154.00 428.00,151.00 428.00,151.00
             428.00,151.00 426.00,154.00 426.00,154.00
             420.09,151.99 416.91,147.84 411.96,144.32
             411.96,144.32 398.00,136.29 398.00,136.29
             394.51,133.71 392.25,130.78 388.00,129.39
             381.50,127.26 373.89,124.72 370.00,132.00 Z
           M 427.00,154.00
           C 427.00,154.00 427.00,155.00 427.00,155.00
             427.00,155.00 426.00,154.00 426.00,154.00
             426.00,154.00 427.00,154.00 427.00,154.00 Z
           M 438.00,159.00
           C 438.00,159.00 438.00,160.00 438.00,160.00
             438.00,160.00 437.00,159.00 437.00,159.00
             437.00,159.00 438.00,159.00 438.00,159.00 Z
           M 445.00,162.00
           C 445.00,162.00 445.00,163.00 445.00,163.00
             445.00,163.00 444.00,162.00 444.00,162.00
             444.00,162.00 445.00,162.00 445.00,162.00 Z
           M 482.00,175.00
           C 482.00,175.00 482.00,176.00 482.00,176.00
             482.00,176.00 481.00,175.00 481.00,175.00
             481.00,175.00 482.00,175.00 482.00,175.00 Z
           M 462.00,187.00
           C 462.00,187.00 462.00,188.00 462.00,188.00
             462.00,188.00 461.00,187.00 461.00,187.00
             461.00,187.00 462.00,187.00 462.00,187.00 Z
           M 475.00,191.00
           C 475.00,191.00 475.00,192.00 475.00,192.00
             475.00,192.00 474.00,191.00 474.00,191.00
             474.00,191.00 475.00,191.00 475.00,191.00 Z
           M 455.00,203.00
           C 455.00,203.00 452.00,210.00 452.00,210.00
             453.70,209.10 455.80,207.69 457.83,207.97
             460.27,208.32 460.31,210.62 462.46,212.64
             465.80,215.80 469.57,216.13 473.23,222.04
             475.78,226.15 475.17,228.03 476.82,230.01
             477.74,231.12 493.93,240.31 483.00,249.00
             483.00,249.00 491.00,252.00 491.00,252.00
             491.00,252.00 486.00,255.00 486.00,255.00
             486.00,255.00 488.00,259.00 488.00,259.00
             488.00,259.00 497.00,257.00 497.00,257.00
             497.00,257.00 495.00,259.00 495.00,259.00
             495.00,259.00 502.00,261.00 502.00,261.00
             502.00,261.00 508.00,270.00 508.00,270.00
             508.00,270.00 501.00,271.00 501.00,271.00
             499.39,276.26 483.57,278.93 482.00,283.00
             482.00,283.00 464.00,289.00 464.00,289.00
             462.26,284.77 461.83,283.25 457.00,283.00
             457.00,283.00 460.00,290.00 460.00,290.00
             460.00,290.00 442.00,291.64 442.00,291.64
             442.00,291.64 432.18,294.35 432.18,294.35
             432.18,294.35 427.00,288.00 427.00,288.00
             427.00,288.00 427.00,294.00 427.00,294.00
             427.00,294.00 420.00,286.00 420.00,286.00
             420.00,286.00 413.00,281.00 413.00,281.00
             413.00,281.00 413.00,280.00 413.00,280.00
             413.00,280.00 415.00,280.00 415.00,280.00
             415.00,280.00 411.00,279.00 411.00,279.00
             411.00,279.00 410.00,269.00 410.00,269.00
             403.46,264.91 400.54,258.33 399.00,251.00
             405.20,249.51 404.86,246.32 408.07,241.97
             408.07,241.97 414.63,234.45 414.63,234.45
             414.63,234.45 424.00,226.00 424.00,226.00
             431.69,218.36 444.19,205.05 455.00,203.00 Z
           M 554.00,227.00
           C 554.00,227.00 554.00,228.00 554.00,228.00
             554.00,228.00 553.00,227.00 553.00,227.00
             553.00,227.00 554.00,227.00 554.00,227.00 Z
           M 379.00,238.00
           C 379.00,238.00 367.00,253.00 367.00,253.00
             364.31,246.22 367.60,239.37 370.00,233.00
             370.00,233.00 379.00,238.00 379.00,238.00 Z
           M 565.00,240.00
           C 565.00,240.00 565.00,241.00 565.00,241.00
             565.00,241.00 564.00,240.00 564.00,240.00
             564.00,240.00 565.00,240.00 565.00,240.00 Z
           M 565.00,242.00
           C 565.00,242.00 565.00,243.00 565.00,243.00
             565.00,243.00 564.00,242.00 564.00,242.00
             564.00,242.00 565.00,242.00 565.00,242.00 Z
           M 398.00,249.00
           C 398.00,249.00 398.00,250.00 398.00,250.00
             398.00,250.00 397.00,249.00 397.00,249.00
             397.00,249.00 398.00,249.00 398.00,249.00 Z
           M 403.00,249.00
           C 403.00,249.00 403.00,250.00 403.00,250.00
             403.00,250.00 402.00,249.00 402.00,249.00
             402.00,249.00 403.00,249.00 403.00,249.00 Z
           M 542.00,249.00
           C 542.00,249.00 542.00,250.00 542.00,250.00
             542.00,250.00 541.00,249.00 541.00,249.00
             541.00,249.00 542.00,249.00 542.00,249.00 Z
           M 217.00,259.00
           C 217.00,259.00 217.00,260.00 217.00,260.00
             217.00,260.00 216.00,259.00 216.00,259.00
             216.00,259.00 217.00,259.00 217.00,259.00 Z
           M 216.00,266.00
           C 216.00,266.00 218.00,268.00 218.00,268.00
             218.00,268.00 218.00,269.00 218.00,269.00
             215.88,268.17 215.48,268.33 216.00,266.00 Z
           M 224.00,269.00
           C 224.00,269.00 224.00,270.00 224.00,270.00
             224.00,270.00 223.00,269.00 223.00,269.00
             223.00,269.00 224.00,269.00 224.00,269.00 Z
           M 542.00,273.00
           C 542.00,273.00 542.00,274.00 542.00,274.00
             542.00,274.00 541.00,273.00 541.00,273.00
             541.00,273.00 542.00,273.00 542.00,273.00 Z
           M 539.00,279.00
           C 539.00,279.00 539.00,280.00 539.00,280.00
             539.00,280.00 538.00,279.00 538.00,279.00
             538.00,279.00 539.00,279.00 539.00,279.00 Z
           M 247.00,281.00
           C 247.00,281.00 247.00,282.00 247.00,282.00
             247.00,282.00 246.00,281.00 246.00,281.00
             246.00,281.00 247.00,281.00 247.00,281.00 Z
           M 542.00,281.00
           C 542.00,281.00 542.00,282.00 542.00,282.00
             542.00,282.00 541.00,281.00 541.00,281.00
             541.00,281.00 542.00,281.00 542.00,281.00 Z
           M 513.00,285.00
           C 513.00,285.00 513.00,286.00 513.00,286.00
             513.00,286.00 512.00,285.00 512.00,285.00
             512.00,285.00 513.00,285.00 513.00,285.00 Z
           M 193.00,290.00
           C 193.56,293.14 194.41,296.85 193.57,300.00
             192.18,305.19 183.10,312.98 178.00,314.49
             176.07,315.06 173.99,314.98 172.00,315.00
             173.20,304.32 184.20,295.06 193.00,290.00 Z
           M 419.00,290.00
           C 419.00,290.00 419.00,291.00 419.00,291.00
             419.00,291.00 418.00,290.00 418.00,290.00
             418.00,290.00 419.00,290.00 419.00,290.00 Z
           M 421.00,293.00
           C 421.00,293.00 421.00,294.00 421.00,294.00
             421.00,294.00 420.00,293.00 420.00,293.00
             420.00,293.00 421.00,293.00 421.00,293.00 Z
           M 523.00,293.00
           C 523.00,293.00 524.00,297.00 524.00,297.00
             522.17,295.37 522.24,295.34 523.00,293.00 Z
           M 549.00,294.00
           C 549.00,294.00 549.00,295.00 549.00,295.00
             549.00,295.00 548.00,294.00 548.00,294.00
             548.00,294.00 549.00,294.00 549.00,294.00 Z
           M 172.00,333.00
           C 172.00,333.00 172.00,334.00 172.00,334.00
             172.00,334.00 171.00,333.00 171.00,333.00
             171.00,333.00 172.00,333.00 172.00,333.00 Z"
        />
      </svg>
    </div>
  );
};

export default Logo;
