import { Logger } from '@babylonjs/core';
import { ThemeService, UserActivityService } from '@jtjs/core-browser';

export class InitService {
  /**
   * Initializes various parts of the application.
   */
  static init() {
    this.initThemeService();
    this.initUserActivityService();

    // Suppress logs in production.
    // Logger.LogLevels = Logger.NoneLogLevel;
    // console.log = () => {};
    // console.error = () => {};
    // console.warn = () => {};
    // console.info = () => {};
    // console.debug = () => {};
    // console.trace = () => {};
  }

  private static initThemeService() {
    ThemeService.registerTheme(
      {
        name: 'Navy Aubergine',
        background: '#12002e',
        foreground: '#031D66',
        disabled: '#6b6b6b',
        text: '#e6e6e6',
        accent: '#1646c9',
        outline: '#4d4d4d',
        button: '#3186cc',
        buttonText: '#e6e6e6',
        focus: '#97ADD8',
      },
      false
    );
    ThemeService.registerTheme({
      name: 'Classic Light',
      background: '#e3e3e3',
      foreground: '#fff',
      disabled: '#3D3D3D',
      text: '#333',
      accent: '#705ebd',
      outline: '#8F8F8F',
      button: '#4e408a',
      buttonText: '#fff',
      focus: '#97ADD8',
    });
    ThemeService.registerTheme(
      {
        name: 'Dark',
        background: '#1c1c1c',
        foreground: '#474747',
        disabled: '#3D3D3D',
        text: '#EEE',
        accent: '#3BAD61',
        outline: '#8F8F8F',
        button: '#5C7EC1',
        buttonText: '#EEE',
        focus: '#97ADD8',
      },
      false
    );

    const defaultTheme = ThemeService.themes[0].name;

    ThemeService.changeTheme(localStorage.getItem('theme') ?? defaultTheme);

    ThemeService.onChangeTheme.subscribe((theme) => {
      localStorage.setItem('theme', theme.name);
    });
  }

  private static initUserActivityService() {
    // Not strictly necessary since this is default, but just wanting to be explicit.
    UserActivityService.activityTimeoutDuration = 3000;
  }
}
