import { Flexbox, Heading, Text } from '@jtjs/react';
import ImageCollection from '../../../shared/ImageCollection';
import ProductCard from '../../../shared/ProductCard';
import screenshot1 from '../../../../../assets/images/blob-screenshot0.jpg';
import DownloadButton from '../../../shared/DownloadButton';
import LinkButton from '../../../shared/LinkButton';

export interface BlobProps {}

export const Blob = ({}: BlobProps) => {
  return (
    <ProductCard
      heading="Blob!"
      supportedDevicesIcons={[
        {
          icon: 'desktop',
          iconType: 'solid',
        },
      ]}
    >
      <section>
        <Heading importance={3}>Details</Heading>
        <Text>
          Skirt around the screen as an ever-hungry block eating less fortunate
          and sentient blocks. Grow as you eat and use your size--whether big or
          small--to your tactical advantage. Use power-ups to escape hairy
          situations and, most importantly, poop golden sparkles. With every
          level being randomly generated, you'll never have the same level
          twice. As you progress, the obstacles become faster and more numerous.
          A great retro experience, being one of our first games, and running at
          a crisp 640x480 resolution. Neat! Can you win when they run faster
          than you?
        </Text>
      </section>

      <section>
        <Heading importance={3}>Screenshots</Heading>
        <ImageCollection
          images={[
            {
              src: screenshot1,
              alt: "A screenshot of Blob!'s main menu. It looks very snazzy.",
            },
          ]}
        />
      </section>

      <section>
        <Heading importance={3}>Resources</Heading>
        <Flexbox>
          <DownloadButton href="https://ucg-download.github.io/files/blob.zip">
            Blob!
          </DownloadButton>
          <LinkButton href="https://ucg-download.github.io/files/blob_cheats.txt">
            Cheats
          </LinkButton>
        </Flexbox>
      </section>
    </ProductCard>
  );
};

export default Blob;
