import { LinkedList } from '@jtjs/core-data';
import { useEffect, useRef, useState } from 'react';
import {
  OverlayData,
  OverlayService,
} from '../../../../services/overlay.service';
import Overlay from './Overlay';

export interface OverlayManagerProps {}

export const OverlayManager = ({}: OverlayManagerProps) => {
  const [overlays, setOverlays] = useState([] as OverlayData[]);

  const hasOverlaysToShow = overlays.length > 0;

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      // Remove overlay when pressing escape.
      if (
        (hasOverlaysToShow && event.key === 'Escape') ||
        event.key === 'Esc'
      ) {
        OverlayService.removeOverlay(OverlayService.activeOverlay?.id ?? '');
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });

  useEffect(() => {
    const handleOverlayChange = (
      _: any,
      allOverlays: LinkedList<OverlayData>
    ) => {
      setOverlays(allOverlays.toArray());
    };

    const onAddListener =
      OverlayService.onAddOverlay.subscribe(handleOverlayChange);
    const onRemoveListener =
      OverlayService.onRemoveOverlay.subscribe(handleOverlayChange);

    return () => {
      OverlayService.onAddOverlay.unsubscribe(onAddListener);
      OverlayService.onRemoveOverlay.unsubscribe(onRemoveListener);
    };
  });

  const overlayComponents = overlays.map((overlayData) => {
    return (
      <Overlay key={overlayData.id} overlayData={overlayData}>
        {overlayData.contents}
      </Overlay>
    );
  });

  return hasOverlaysToShow ? (
    <div
      className="overlay-manager"
      style={{
        backgroundColor: hasOverlaysToShow
          ? 'rgba(0, 0, 0, 0.5)'
          : 'transparent',
        pointerEvents: !hasOverlaysToShow ? 'none' : undefined,
      }}
    >
      {overlayComponents}
    </div>
  ) : null;
};

export default OverlayManager;
