import { Flexbox, formatClassName, Icon, Text } from '@jtjs/react';
import { HTMLProps, useState } from 'react';

export interface LightroomAudioControlsProps extends HTMLProps<HTMLDivElement> {
  /**
   * Whether the audio these controls manage is currently playing.
   */
  isPlaying: boolean;
  audioTrackName: string;
  // trackAsBabylonSound: Nullable<Sound>;
  currentTrackTime: number;
  currentTrackDuration: number;
  autohideUiEnabled: boolean;
  onClickPlay: () => void;
  onChangeTrack: (trackName: string) => void;
  onChangeAutohideUiEnabled: (enabled: boolean) => void;
  /**
   * What to do when the user wants to scrub the audio track to go to a different
   * point in time in the playback.
   */
  onScrubTrack: (time: number) => void;
  onStopScrubTrack: () => void;
  disabled: boolean;
  availableTrackNames?: string[];
}

export const LightroomAudioControls = ({
  isPlaying,
  audioTrackName,
  availableTrackNames,
  currentTrackTime,
  currentTrackDuration,
  autohideUiEnabled,
  onClickPlay,
  onChangeTrack,
  onChangeAutohideUiEnabled,
  onScrubTrack,
  onStopScrubTrack,
  disabled,
  className,
  ...otherProps
}: LightroomAudioControlsProps) => {
  const [showTrackList, setShowTrackList] = useState(false);

  const convertSecondsToMinutesDisplay = (seconds: number) => {
    const inMinutes = seconds / 60;
    const secondsAsPartOfMinute = (inMinutes % 1) * 60;

    return `${Math.floor(inMinutes).toFixed(0)}:${Math.floor(
      secondsAsPartOfMinute
    )
      .toFixed(0)
      .padStart(2, '0')}`;
  };

  return (
    <div className={formatClassName('lightroom-audio-controls', className)}>
      <Flexbox direction="column" spacing="0" horizontalAlignment="center">
        <Flexbox
          className="control-buttons"
          direction="row"
          horizontalAlignment="center"
          verticalAlignment="center"
        >
          <button
            className="auto-hide-ui-button"
            title={`${
              autohideUiEnabled ? 'Disable' : 'Enable'
            } Autohide UI After Delay`}
            onClick={() => onChangeAutohideUiEnabled(!autohideUiEnabled)}
          >
            <Icon
              icon={autohideUiEnabled ? 'eye' : 'eye-slash'}
              iconType="solid"
            />
          </button>
          <button
            title={isPlaying ? 'Pause' : 'Play'}
            className="play-button"
            onClick={onClickPlay}
            disabled={disabled}
          >
            <Icon icon={isPlaying ? 'pause' : 'play'} iconType="solid" />
          </button>
          <button
            title={`${showTrackList ? 'Hide' : 'Show'} Additional Tracks`}
            className={`song-selection-button${
              showTrackList ? ' expanded' : ''
            }`}
            onClick={() => setShowTrackList((val) => !val)}
          >
            <Icon icon="plus" iconType="solid" />
          </button>
        </Flexbox>

        <Flexbox
          className="track-scrubber-container"
          direction="column"
          horizontalAlignment="center"
        >
          <Text>
            {convertSecondsToMinutesDisplay(currentTrackTime)}/
            {convertSecondsToMinutesDisplay(currentTrackDuration)}
          </Text>
          {/* See Lightroom TODO for why this is commented out */}
          {/* <input
            type="range"
            min="0"
            max={currentTrackDuration}
            onChange={(event) => {
              onScrubTrack(+event.target.value);
            }}
            onPointerUp={() => onStopScrubTrack()}
            value={currentTrackTime}
          /> */}
        </Flexbox>

        {showTrackList && (
          <Flexbox
            className="track-list"
            direction="column"
            horizontalAlignment="center"
            wrap={false}
          >
            {availableTrackNames?.map((trackName) => {
              return (
                <button
                  key={trackName}
                  onClick={() => {
                    onChangeTrack && onChangeTrack(trackName);
                  }}
                  aria-label={`change track to ${trackName}`}
                >
                  {trackName}
                </button>
              );
            })}
          </Flexbox>
        )}
      </Flexbox>
    </div>
  );
};

export default LightroomAudioControls;
