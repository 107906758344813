import AboutCard from '../AboutCard';
import portrait from '../../../../../assets/images/josh.jpg';
import { Link, Text } from '@jtjs/react';

export interface JoshProps {}

export const Josh = ({}: JoshProps) => {
  return (
    <AboutCard
      personName="Josh"
      portraitProps={{
        src: portrait,
        alt: 'A picture of Josh, founder of Unlucky Cricket Games',
        style: {
          backgroundPosition: '0 -300px',
        },
      }}
    >
      <Text>
        Hi there! My name's Josh, and I'm the founder of Unlucky Cricket Games.
        I wear a lotta hats around here, including but not limited to: designer,
        audio guy, web wizard, harshest critic, and more! I'm primarily a
        programmer, and I really wish I could just focus on that and let other
        talented people handle the rest, but alas.
        <br />
        <br />I founded Unlucky Cricket Games when I started making little games
        in my adolescence. I needed something to go by, and it sounded alright
        to me, so here we are! I got started with some software called
        HyperStudio, writing code in its scripting language HyperLogo. All in
        all, it's pretty crummy software for game development (not surprising,
        seeing as how it's meant to be a presentational tool), so I moved onto
        other solutions like BlitzBasic and eventually{' '}
        <Link href="https://unity.com/" target="_blank">
          Unity
        </Link>
        . I experimented quite a bit with Unity and even did some contracting
        with what I'd learned for a while. It was a lot of fun!
        <br />
        <br />
        Now, I primarily work with web technologies. For example, I wrote this
        site from scratch using{' '}
        <Link href="https://reactjs.org/" target="_blank">
          React
        </Link>
        , along with some other nifty tools and libraries for frontend
        development (like a collection of frontend libraries I wrote). I focus
        mainly on the client side, but I am capable of working across the full
        stack.
        <br />
        <br />
        Anywho, I hope you like the site and the things I've made! I put 💖 into
        everything I make.
      </Text>
    </AboutCard>
  );
};

export default Josh;
