import { Card, CardProps, formatClassName } from '@jtjs/react';

export interface ContentCardProps extends CardProps {}

/**
 * Card that's meant to be used for main content of a page.
 */
export const ContentCard = ({ className, ...otherProps }: ContentCardProps) => {
  return (
    <Card
      className={formatClassName('content-card', className)}
      {...otherProps}
    />
  );
};

export default ContentCard;
