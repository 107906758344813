import { Heading, Link, Text } from '@jtjs/react';
import screenshot1 from '../../../../../assets/images/ga-arcade-screenshot.jpg';
import DownloadButton from '../../../shared/DownloadButton';
import ImageCollection from '../../../shared/ImageCollection';
import ProductCard from '../../../shared/ProductCard';

export interface GalacticAssaultArcadeProps {}

const GalacticAssaultArcade = ({}: GalacticAssaultArcadeProps) => {
  return (
    <ProductCard
      heading="Galactic Assault ARCADE"
      supportedDevicesIcons={[
        {
          icon: 'desktop',
          iconType: 'solid',
        },
      ]}
    >
      <section>
        <Heading importance={3}>Details</Heading>
        <Text>
          A game we made as a tech demo and practice while learning the{' '}
          <Link href="https://godotengine.org/" target="_blank">
            Godot Game Engine
          </Link>
          .
          <br />
          <br />
          Zoom around the screen and take out neverending waves of baddies while using powerups to help even the odds.
          Tackle the game solo or with a buddy if you have two gamepads handy!
        </Text>
      </section>

      <section>
        <Heading importance={3}>Screenshots</Heading>
        <ImageCollection
          images={[
            {
              src: screenshot1,
              alt: 'Galactic Assault ARCADE screenshot showing the player beset by enemies',
            },
          ]}
        />
      </section>

      <section>
        <Heading importance={3}>Resources</Heading>
        <DownloadButton href="https://raw.github.com/mrCamelCode/assets/main/downloads/galactic-assault-arcade.zip">
          Galactic Assault ARCADE
        </DownloadButton>
      </section>
    </ProductCard>
  );
};

export default GalacticAssaultArcade;
