import { Button, Flexbox, Heading, Text } from '@jtjs/react';
import { OverlayService } from '../../../services/overlay.service';
import ContentCard from '../../shared/ContentCard';

export interface EpilepsyWarningAcknowledgementProps {}

export const EpilepsyWarningAcknowledgement =
  ({}: EpilepsyWarningAcknowledgementProps) => {
    return (
      <ContentCard>
        <Heading importance={2}>Epilepsy Warning</Heading>

        <Text>
          The Lightroom features flashing lights and patterns that may affect
          those with certain types of epilepsy. User discretion is advised.
          <br />
          <br />
          Please click "Okay" to no longer receive this warning.
        </Text>

        <Flexbox
          style={{
            width: '100%',
          }}
          horizontalAlignment="center"
        >
          <Button
            onClick={() => {
              localStorage.setItem(
                'lightroom_epilepsyWarningAcknowledged',
                'true'
              );

              // If they're interacting with this, it should be the last opened overlay, and should therefore be the
              // last in the list of overlays, so we can close that one and that should close this overlay.
              OverlayService.removeOverlay(
                OverlayService.activeOverlay?.id ?? ''
              );
            }}
          >
            Okay
          </Button>
        </Flexbox>
      </ContentCard>
    );
  };

export default EpilepsyWarningAcknowledgement;
