import { ThemeService } from '@jtjs/core-browser';
import { Flexbox, useTheme } from '@jtjs/react';
import ThemeSummary from './ThemeSummary';

export interface ThemePickerProps {}

export const ThemePicker = ({}: ThemePickerProps) => {
  const [theme, setTheme] = useTheme();

  return (
    <Flexbox className="theme-picker" direction="column">
      {ThemeService.themes.map((themeData) => {
        const isCurrentlySelected = themeData.name === theme.name;

        return (
          <button
            className="theme-selection-button override-button"
            style={{
              paddingBottom: '0.5rem',
              ...(isCurrentlySelected
                ? {
                    borderLeft: '5px solid var(--jtjs-theme-button-lightened)',
                  }
                : {}),
            }}
            aria-label={
              isCurrentlySelected
                ? `${themeData.name} is currently selected theme`
                : `Change theme to ${themeData.name}`
            }
            onClick={() => setTheme(themeData.name)}
            key={themeData.name}
          >
            <ThemeSummary theme={themeData} />
          </button>
        );
      })}
    </Flexbox>
  );
};

export default ThemePicker;
