import { Flexbox, Text } from '@jtjs/react';
import PreviewImageCard from '../../shared/PreviewImageCard';
import gaScreenshot from '../../../../assets/images/ga-screenshot0.jpg';
import gaArcadeScreenshot from '../../../../assets/images/ga-arcade-screenshot.jpg';
import blobScreenshot from '../../../../assets/images/blob-screenshot0.jpg';
import GameCardHeading from './GameCardHeading';

export interface GamesProps {}

export const Games = ({}: GamesProps) => {
  return (
    <Flexbox id="games-page">
      <PreviewImageCard
        src={gaScreenshot}
        headingComponent={() => (
          <GameCardHeading
            icons={[
              {
                icon: 'desktop',
                iconType: 'solid',
              },
            ]}
          >
            Galactic Assault
          </GameCardHeading>
        )}
        linkTo="galactic-assault"
        linkText="Pew Baddies"
      >
        <Text>
          A top-down 2D space shooter with procedurally-generated bosses, local co-op, and full gamepad support.
        </Text>
      </PreviewImageCard>

      <PreviewImageCard
        src={gaArcadeScreenshot}
        headingComponent={() => (
          <GameCardHeading
            icons={[
              {
                icon: 'desktop',
                iconType: 'solid',
              },
            ]}
          >
            Galactic Assault ARCADE
          </GameCardHeading>
        )}
        linkTo="galactic-assault-arcade"
        linkText="LoFi Pews"
      >
        <Text>
          A top-down 2D wave shooter that we made as a tech demo learning Godot. Features local multiplayer and gamepad
          support.
        </Text>
      </PreviewImageCard>

      <PreviewImageCard
        src={blobScreenshot}
        headingComponent={() => (
          <GameCardHeading
            icons={[
              {
                icon: 'desktop',
                iconType: 'solid',
              },
            ]}
          >
            Blob!
          </GameCardHeading>
        )}
        linkTo="blob"
        linkText="Nom Squares"
      >
        <Text>An arcade game about eating squares and pooping golden sparkles.</Text>
      </PreviewImageCard>
    </Flexbox>
  );
};

export default Games;
