import { Flexbox, Text } from '@jtjs/react';
import PreviewImageCard from '../../shared/PreviewImageCard';
import josh from '../../../../assets/images/josh.jpg';
import devyn from '../../../../assets/images/devyn.jpg';
import sam from '../../../../assets/images/sam.jpg';
import jack from '../../../../assets/images/jack.jpg';
import karl from '../../../../assets/images/karl.jpg';

export interface AboutProps {}

export const About = ({}: AboutProps) => {
  return (
    <Flexbox id="about-page">
      <PreviewImageCard
        src={josh}
        heading="Josh"
        linkTo="josh"
        linkText="Learn About Josh"
      >
        <Text>Founder</Text>
      </PreviewImageCard>
      <PreviewImageCard src={devyn} heading="Devyn">
        <Text>Artist</Text>
      </PreviewImageCard>
      <PreviewImageCard
        src={sam}
        style={{
          backgroundPosition: 'center',
        }}
        heading="Sam"
        linkTo="sam"
        linkText="Learn About Sam"
      >
        <Text>Musician</Text>
      </PreviewImageCard>
      <PreviewImageCard
        src={jack}
        style={{
          backgroundPosition: 'center',
        }}
        heading="Jack"
        linkTo="jack"
        linkText="Learn About Jack"
      >
        <Text>Musician</Text>
      </PreviewImageCard>
      <PreviewImageCard
        src={karl}
        heading="Karl"
        style={{
          backgroundPosition: 'center',
        }}
      >
        <Text>Musician</Text>
      </PreviewImageCard>
    </Flexbox>
  );
};

export default About;
