import { Theme } from '@jtjs/core-browser';
import { Flexbox, Heading } from '@jtjs/react';
import ColorDot from './ColorDot';

export interface ThemeSummaryProps {
  theme: Theme;
}

export const ThemeSummary = ({ theme, ...otherProps }: ThemeSummaryProps) => {
  return (
    <div className="theme-summary">
      <Heading importance={6} style={{ margin: '0.5rem 0' }}>
        {theme.name}
      </Heading>
      <Flexbox verticalAlignment="center">
        {Object.keys(theme).map((themeProperty) => {
          const themePropertyKey = themeProperty as keyof Theme;

          return themeProperty !== 'name' && theme[themePropertyKey] ? (
            <ColorDot color={theme[themePropertyKey]} key={themePropertyKey} />
          ) : null;
        })}
      </Flexbox>
    </div>
  );
};

export default ThemeSummary;
