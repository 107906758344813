import { Flexbox, formatClassName, Icon, IconProps, Text } from '@jtjs/react';
import { ComponentType } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export interface MobileNavLinkIconProps extends LinkProps {
  imgComponent?: ComponentType;
  imgOptions?: {
    src: string;
    alt: string;
  };
  iconOptions?: IconProps;
  label: string;
}

export const MobileNavLinkIcon = ({
  imgComponent: Component,
  imgOptions,
  iconOptions,
  label,
  className,
  ...otherProps
}: MobileNavLinkIconProps) => {
  let VisualComponent: ComponentType;
  if (Component) {
    VisualComponent = () => <Component />;
  } else if (imgOptions) {
    VisualComponent = () => <img src={imgOptions.src} alt={imgOptions.alt} />;
  } else if (iconOptions) {
    VisualComponent = () => <Icon {...iconOptions} />;
  } else {
    VisualComponent = () => <></>;
  }

  return (
    <Link
      className={formatClassName('mobile-nav-link-icon', className)}
      {...otherProps}
    >
      <Flexbox
        verticalAlignment="center"
        horizontalAlignment="center"
        direction="column"
        spacing="0"
        style={{
          height: '100%',
        }}
      >
        <VisualComponent />
        <Text
          style={{
            margin: 0,
            textAlign: 'center',
          }}
        >
          {label}
        </Text>
      </Flexbox>
    </Link>
  );
};

export default MobileNavLinkIcon;
