import { CardProps, Flexbox, formatClassName, Heading } from '@jtjs/react';
import ContentCard from '../../shared/ContentCard';
import Portrait, { PortraitProps } from './Portrait';

export interface AboutCardProps extends CardProps {
  portraitProps: PortraitProps;
  personName: string;
}

export const AboutCard = ({
  personName,
  className,
  portraitProps,
  children,
  ...otherProps
}: AboutCardProps) => {
  return (
    <ContentCard
      className={formatClassName('about-card', className)}
      {...otherProps}
    >
      <Flexbox horizontalAlignment="center" verticalAlignment="center">
        <Portrait {...portraitProps} />
      </Flexbox>
      <Heading importance={3}>About {personName}</Heading>

      <div className="content">{children}</div>
    </ContentCard>
  );
};

export default AboutCard;
