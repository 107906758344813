import { Heading, Link, Text } from '@jtjs/react';
import ImageCollection from '../../../shared/ImageCollection';
import ProductCard from '../../../shared/ProductCard';
import screenshot1 from '../../../../../assets/images/virtuoso-screenshot0.jpg';
import DownloadButton from '../../../shared/DownloadButton';
import { Link as RouteLink } from 'react-router-dom';
import { PATHS } from '../../../Router';

export interface VirtuosoProps {}

export const Virtuoso = ({}: VirtuosoProps) => {
  return (
    <ProductCard
      heading="Virtuoso"
      supportedDevicesIcons={[
        {
          icon: 'desktop',
          iconType: 'solid',
        },
      ]}
    >
      <section>
        <Heading importance={3}>Details</Heading>
        <Text>
          Are you an indie game developer or music enthusiast? Well, we are, and
          we were sick and tired of never being able to make our music exactly
          how we wanted it. Instead, we had to rely on libraries of loops. While
          they may be expansive, sifting through hundreds of audio files just to
          find the progression that sounds as close as possible to the thing you
          want is silly. Why couldn't we just feed a program the notes we want
          and how long to play them, and have it spit out nice audio files for
          us? Well, that's what Virtuoso was built to do. With Virtuoso, you can
          type up a text file similar to the one pictured below (which is being
          displayed in Atom) and then just tell Virtuoso to create it. Virtuoso
          will create pure tones (sorry, you can't make a saxophone sound) that
          match the frequencies of the notes you specify. You can also specify
          the speed of playback, tracks and measures, durations of the notes,
          their octaves, and chords.
          <br /> <br />
          With Virtuoso, you can make all sorts of neat things! We even
          transcribed the song Still Alive from the popular game{' '}
          <Link
            href="http://store.steampowered.com/app/400/Portal/"
            target="_blank"
          >
            Portal
          </Link>
          . You can listen to it in the{' '}
          <RouteLink className="jtjs-link" to={PATHS.lightroom.path}>
            Lightroom
          </RouteLink>
          !
          <br /> <br />
          You command Virtuoso to compile your text files into music via a
          command line window. This can seem intimidating at first if you're not
          used to using a command line, but we've got you covered. Virtuoso uses
          its own command line so there's no need to learn any fancy
          programmery. Virtuoso only has a small set of commands to get its job
          done, and you can get a list of all of them by typing "help" (no
          quotes) and hitting enter in the command window that comes up when you
          run Virtuoso. To get a running start on learning how to use Virtuoso,
          download the zip archive with the download button down there, extract
          the files, run Virtuoso, and then type, "open example" (no quotes),
          then hit Enter. That will open up an example song specifically written
          to help you learn.
          <br /> <br />
          Now, go make your music!
          <br />
          <br />
          Virtuoso was created by Joshua Truitt.
        </Text>
      </section>

      <section>
        <Heading importance={3}>Screenshots</Heading>
        <ImageCollection
          images={[
            {
              src: screenshot1,
              alt: "Image of a text editor showing Virtuoso's means of expressing musical data through text.",
            },
          ]}
        />
      </section>

      <section>
        <Heading importance={3}>Resources</Heading>
        <DownloadButton href="https://ucg-download.github.io/files/Virtuoso.zip">
          Virtuoso
        </DownloadButton>
      </section>
    </ProductCard>
  );
};

export default Virtuoso;
