import { formatClassName, Icon } from '@jtjs/react';
import { HTMLProps } from 'react';

export interface LinkButtonProps extends HTMLProps<HTMLAnchorElement> {}

export const LinkButton = ({
  className,
  children,
  ...otherProps
}: LinkButtonProps) => {
  return (
    <a
      className={formatClassName('jtjs-button link-button', className)}
      target="_blank"
      {...otherProps}
    >
      <Icon
        icon="external-link-alt"
        iconType="solid"
        aria-label="follow-link"
        style={{
          marginRight: '0.5rem',
        }}
      />
      {children}
    </a>
  );
};

export default LinkButton;
