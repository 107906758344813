import { Heading, Text } from '@jtjs/react';
import DownloadButton from '../../../shared/DownloadButton';
import ProductCard from '../../../shared/ProductCard';
import screenshot1 from '../../../../../assets/images/ga-screenshot0.jpg';
import ImageCollection from '../../../shared/ImageCollection';

export interface GalacticAssaultProps {}

export const GalacticAssault = ({}: GalacticAssaultProps) => {
  return (
    <ProductCard
      heading="Galactic Assault"
      supportedDevicesIcons={[
        {
          icon: 'desktop',
          iconType: 'solid',
        },
      ]}
    >
      <section>
        <Heading importance={3}>Details</Heading>
        <Text>
          Our latest game, Galactic Assault is a top-down arcade space shooter
          available for Windows. It features couch co-op if you've got gamepads
          handy, endless play, and a whole lot of battle. Face off against
          procedurally generated bosses at milestone levels so you'll never have
          the same challenge twice. How far can you get?
        </Text>
      </section>

      <section>
        <Heading importance={3}>Screenshots</Heading>
        <ImageCollection
          images={[
            {
              src: screenshot1,
              alt: 'Galactic Assault screenshot showing the player beset by enemies',
            },
          ]}
        />
      </section>

      <section>
        <Heading importance={3}>Resources</Heading>
        <DownloadButton href="https://ucg-download.github.io/files/ga_build0.2.0.zip" download>
          Galactic Assault
        </DownloadButton>
      </section>
    </ProductCard>
  );
};

export default GalacticAssault;
