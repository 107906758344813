import AboutCard from '../AboutCard';
import portrait from '../../../../../assets/images/jack.jpg';
import { Text } from '@jtjs/react';

export interface JackProps {}

export const Jack = ({}: JackProps) => {
  return (
    <AboutCard
      personName="Jack"
      portraitProps={{
        src: portrait,
        alt: 'Picture of Jack, an independent musician that has worked with Unlucky Cricket Games',
      }}
    >
      <Text>
        Jack is currently studying biology at Hope College. At Hope, he also
        plays on the men's soccer team and is involved in Greek life. Jack hopes
        to go to dental school after graduating from Hope to pursue a career in
        Dentistry.
      </Text>
    </AboutCard>
  );
};

export default Jack;
