import { Flexbox, Text } from '@jtjs/react';
import { useRecoilValue } from 'recoil';
import { fullscreenState } from '../../../state/fullscreen.atom';

export interface SiteFooterProps {}

export const SiteFooter = ({}: SiteFooterProps) => {
  const fullscreen = useRecoilValue(fullscreenState);

  return (
    <footer id="site-footer" className={fullscreen ? 'hidden' : ''}>
      <Flexbox
        style={{
          height: '100%',
          top: 0,
        }}
        verticalAlignment="center"
        horizontalAlignment="center"
        direction="column"
        spacing="0.5rem"
      >
        <Text
          style={{
            margin: 0,
          }}
        >
          &copy; Unlucky Cricket Games
        </Text>
        <Text
          style={{
            margin: 0,
          }}
        >
          All Rights Reserved
        </Text>
      </Flexbox>
    </footer>
  );
};

export default SiteFooter;
