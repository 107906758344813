import { Flexbox, formatClassName } from '@jtjs/react';
import { DetailedHTMLProps, useEffect, useState } from 'react';
import { OverlayService } from '../../services/overlay.service';

export interface ExpandableImageProps
  extends DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

export const ExpandableImage = ({
  className,
  alt,
  style,
  ...otherProps
}: ExpandableImageProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const overlayId = 'image-viewer';

  useEffect(() => {
    if (isExpanded) {
      OverlayService.addOverlay({
        contents: (
          <img
            alt={alt}
            className="expanded-image"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
            onClick={() => {
              OverlayService.removeOverlay(
                OverlayService.activeOverlay?.id ?? ''
              );
            }}
            {...otherProps}
          />
        ),
        id: overlayId,
      });
    } else {
      // If it switched from being expanded to now NOT being expanded, we'll remove the overlay.
      // This catches when someone navigates to the image button with the keyboard.
      // Clicking once expands, and their focus remains on the button. Clicking once
      // allows them to unexpand and close the overlay.
      OverlayService.removeOverlay(overlayId);
    }
  }, [isExpanded]);

  useEffect(() => {
    const onRemoveOverlayListener = OverlayService.onRemoveOverlay.subscribe(
      (removedOverlay) => {
        if (removedOverlay.id === overlayId) {
          setIsExpanded(false);
        }
      }
    );

    return () =>
      OverlayService.onRemoveOverlay.unsubscribe(onRemoveOverlayListener);
  });

  return (
    <button
      className="expandable-image-button"
      aria-label={
        isExpanded
          ? `Click to close the following image expansion: ${alt}`
          : `Click to see the following image larger: ${alt}`
      }
      style={style}
      onClick={() => {
        setIsExpanded((prevIsExpanded) => !prevIsExpanded);
      }}
    >
      <img
        tabIndex={1}
        alt={alt}
        className={formatClassName(
          `expandable-image${isExpanded ? ' expanded' : ''}`,
          className
        )}
        {...otherProps}
      />
    </button>
  );
};

export default ExpandableImage;
