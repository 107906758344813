import { Engine, Nullable, Scene } from '@babylonjs/core';
import { Event } from '@jtjs/core-data';
import { EngineManager } from './engine.manager';

export enum SceneName {
  BACKGROUND = 'background',
  LIGHTROOM = 'lightroom',
}

export type SceneChangeHandler = (scene: Scene, sceneName: SceneName) => void;

export class SceneManager {
  public static onChangeScene = new Event<SceneChangeHandler>();

  private static _scene: Scene;
  public static get scene(): Scene {
    return this._scene;
  }
  private static set scene(val: Scene) {
    // Cleanup the current scene before switching scenes.
    this._scene?.dispose();

    this._scene = val;
  }

  private static _sceneName: SceneName;
  public static get sceneName(): SceneName {
    return this._sceneName;
  }

  /**
   * Changes the scene to the specified scene and performs all necessary cleanup. This will trigger
   * the onChangeScene event if the scene successfully changes. The scene won't change if the specified
   * `sceneName` is already the currently active scene.
   *
   * @param sceneName - The name of the scene to change to.
   * @param force - (Optional, defaults to false) Whether to force the scene change. If this is true, the scene will be cleaned up
   * and set to the specified scene _even if_ the specified scene is the currently active scene. When force is active,
   * onSceneChange will _still be triggered_, even if the new scene is the same as the old one.
   * @param engine - (Optional, defaults to the result of calling `EngineManager.changeEngine()`) The engine object to use for the scene.
   *
   * @returns - Whether the scene actually changed. The scene won't change if the target
   * scene is the scene that's already active.
   */
  public static changeSceneTo(
    sceneName: SceneName,
    force = false,
    // Can't default engine here to `EngineManager.changeEngine`, because that causes the engine to be changed
    // on every call of `changeSceneTo`, including calls where the scene doesn't actually change. We have
    // default in the method body.
    engine: Nullable<Engine> = null
  ): boolean {
    if (sceneName === this.sceneName && !force) {
      // Do nothing if the scene isn't different from the current one.
      return false;
    }

    if (!engine) {
      engine = EngineManager.changeEngine();
    }

    this.scene = new Scene(engine);
    this._sceneName = sceneName;

    this.onChangeScene.trigger(this.scene, this.sceneName);

    return true;
  }
}
