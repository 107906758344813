import { Flexbox, Heading, HeadingProps, IconProps } from '@jtjs/react';
import SupportedDeviceBadge from '../../shared/SupportedDeviceBadge';

export interface GameCardHeadingProps extends HeadingProps {
  /**
   * The icons to display for supported devices for the game.
   */
  icons: IconProps[];
}

export const GameCardHeading = ({
  icons,
  ...otherProps
}: GameCardHeadingProps) => {
  return (
    <Flexbox
      direction="column"
      horizontalAlignment="center"
      className="game-card-heading contrasted-element"
    >
      <Heading importance={2} style={{ marginBottom: 0 }} {...otherProps} />

      <Flexbox>
        {icons.map((icon) => {
          return <SupportedDeviceBadge {...icon} key={icon.icon} />;
        })}
      </Flexbox>
    </Flexbox>
  );
};

export default GameCardHeading;
