import { Engine, Nullable, Sound } from '@babylonjs/core';
import { Heading, Icon, isBreakpointSmallerThan, useBreakpoint, useUserActive } from '@jtjs/react';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { fullscreenState } from '../../../state/fullscreen.atom';
import { SceneManager } from '../../../webgl/managers/scene.manager';
import { LightroomScene } from '../../../webgl/scenes/lightroom.scene';
import LightroomAudioControls from './LightroomAudioControls';
import sunlight from '../../../../assets/music/sunlight.mp3';
import blobTheme from '../../../../assets/music/blob-theme.mp3';
import gaTheme from '../../../../assets/music/ga-theme.mp3';
import stillAlive from '../../../../assets/music/still-alive.mp3';

export interface LightroomProps {}

/*
 * TODO: I cannot for the life of me figure out how the hell to get an accurate read on where
 * in the audio's duration the playback CURRENTLY is, so until I can figure that out, the scrubber
 * will be removed from the audio controls.
 */
export const Lightroom = ({}: LightroomProps) => {
  const songData = {
    sunlight: {
      name: 'Sunlight',
      path: sunlight,
    },
    blobTheme: {
      name: 'Blob Theme',
      path: blobTheme,
    },
    gaTheme: {
      name: 'Galactic Assault Theme',
      path: gaTheme,
    },
    stillAlive: {
      name: 'Still Alive',
      path: stillAlive,
    },
  };

  const isUserActive = useUserActive();

  const [shouldHideUi, setShouldHideUi] = useState(false);
  const [fullscreen, setFullscreen] = useRecoilState(fullscreenState);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [soundIsLoading, setSoundIsLoading] = useState(false);
  const [currentSong, setCurrentSong] = useState(songData.sunlight);
  const [currentSongAsBabylonSound, setCurrentSongAsBabylonSound] = useState<Nullable<Sound>>(null);
  const [currentTrackTime, setCurrentTrackTime] = useState(0);
  const [currentTrackDuration, setCurrentTrackDuration] = useState(0);

  const [isScrubbingTrack, setIsScrubbingTrack] = useState(false);
  const [freezeTrackTime, setFreezeTrackTime] = useState(false);

  const savedAutohideUi = localStorage.getItem('lightroom_autohideUi');
  const [autohideUi, setAutohideUi] = useState(isNil(savedAutohideUi) ? true : savedAutohideUi === 'true');

  const breakpoint = useBreakpoint();

  const playCurrentSong = () => {
    currentSongAsBabylonSound?.play();
  };

  useEffect(() => {
    // Persist changes to autohide UI.
    localStorage.setItem('lightroom_autohideUi', autohideUi.toString());
  }, [autohideUi]);

  useEffect(() => {
    if (!isUserActive) {
      if (isAudioPlaying && autohideUi) {
        setShouldHideUi(true);
      } else {
        setShouldHideUi(false);
      }
    } else {
      setShouldHideUi(false);
    }
  }, [isUserActive, isAudioPlaying, autohideUi]);

  useEffect(() => {
    const handleEnded = () => {
      setIsAudioPlaying(false);
    };

    const updateDurationTracker = setInterval(() => {
      if (!isScrubbingTrack && !freezeTrackTime) {
        // currentTime returns the amount of time the sound has been playing, NOT the time
        // it is in the playback. So, if you .play() with an offset of 24, its currenTime will
        // start at 0, NOT 24.
        // TODO: Find a way to tell where it is in the current playback.
        // console.log('NEW TIME:', currentSongAsBabylonSound?.getSoundSource());
        // console.log(
        //   'duration:',
        //   currentSongAsBabylonSound?.getAudioBuffer()?.duration
        // );
        // setCurrentTrackTime(currentSongAsBabylonSound?.currentTime ?? 0);
      }

      setCurrentTrackTime(currentSongAsBabylonSound?.currentTime ?? 0);
      setCurrentTrackDuration(currentSongAsBabylonSound?.getAudioBuffer()?.duration ?? 0);

      currentSongAsBabylonSound?.getSoundSource()?.addEventListener('ended', handleEnded);
    }, 500);

    return () => {
      clearInterval(updateDurationTracker);
      currentSongAsBabylonSound?.getSoundSource()?.removeEventListener('ended', handleEnded);
    };
  }, [currentSongAsBabylonSound, isScrubbingTrack, freezeTrackTime]);

  useEffect(() => {
    let bufferSize = undefined;
    if (isBreakpointSmallerThan(breakpoint, 'sm')) {
      bufferSize = 64;
    } else if (isBreakpointSmallerThan(breakpoint, 'md')) {
      bufferSize = 128;
    } else if (isBreakpointSmallerThan(breakpoint, 'xl')) {
      bufferSize = 256;
    }

    LightroomScene.create(bufferSize, true);

    // When we regenerate the scene, the sound vanishes with it, so we'll turn
    // the playback off so things get reinitialized.
    setIsAudioPlaying(false);
  }, [breakpoint]);

  useEffect(() => {
    let isMounted = true;

    setIsAudioPlaying(false);

    SceneManager.scene.getSoundByName('music')?.dispose();

    setSoundIsLoading(true);
    setCurrentSongAsBabylonSound(
      new Sound('music', currentSong.path, SceneManager.scene, () => {
        if (isMounted) {
          setSoundIsLoading(false);
        }
      })
    );

    return () => {
      isMounted = false;
    };
  }, [currentSong, breakpoint]);

  useEffect(() => {
    // Because of browser restrictions, you can only unlock an audio context as the result
    // of a user gesture, so handling unlocking for playing/resuming has to be managed by
    // the on click handler. However, we lock the context to start so that we can ensure it's
    // locked if our playing state is off.
    if (!isAudioPlaying) {
      Engine.audioEngine.lock();
    } else {
      setFreezeTrackTime(false);
    }
  }, [isAudioPlaying]);

  const handleChangeTrack = (trackName: string) => {
    const newSong = Object.values(songData).find((data) => data.name === trackName);

    if (newSong) {
      setCurrentSong(newSong);
    }
  };

  const handleScrubTrack = (time: number) => {
    setIsScrubbingTrack(true);
    setIsAudioPlaying(() => {
      currentSongAsBabylonSound?.pause();

      setCurrentTrackTime(time);

      return false;
    });
  };

  const handleStopScrubTrack = () => {
    setIsScrubbingTrack(false);
    setFreezeTrackTime(true);
  };

  return (
    <div id="lightroom-page" className={shouldHideUi ? 'hidden' : ''}>
      <Heading
        importance={2}
        style={{
          textAlign: 'center',
        }}
      >
        {currentSong.name}
      </Heading>

      <LightroomAudioControls
        disabled={soundIsLoading}
        isPlaying={isAudioPlaying}
        audioTrackName={currentSong.name}
        availableTrackNames={Object.values(songData).map((data) => data.name)}
        currentTrackTime={currentTrackTime}
        currentTrackDuration={currentTrackDuration}
        autohideUiEnabled={autohideUi}
        onClickPlay={() => {
          setIsAudioPlaying((wasAudioPlaying) => {
            const playing = !wasAudioPlaying;

            if (playing) {
              if (!Engine.audioEngine.unlocked) {
                Engine.audioEngine.unlock();
              }

              if (!currentSongAsBabylonSound?.isPlaying) {
                playCurrentSong();
              }
            } else {
              currentSongAsBabylonSound?.pause();
            }

            return playing;
          });
        }}
        onChangeTrack={handleChangeTrack}
        onChangeAutohideUiEnabled={(enabled) => {
          setAutohideUi(enabled);
        }}
        onScrubTrack={handleScrubTrack}
        onStopScrubTrack={handleStopScrubTrack}
      />

      <button
        id="fullscreen-button"
        aria-label={fullscreen ? 'disable fullscreen' : 'enable fullscreen'}
        onClick={() => setFullscreen((currentValue) => !currentValue)}
      >
        <Icon icon={fullscreen ? 'compress' : 'expand'} iconType="solid" />
      </button>
    </div>
  );
};

export default Lightroom;
