import { formatClassName } from '@jtjs/react';
import ExpandableImage, {
  ExpandableImageProps,
} from '../../shared/ExpandableImage';

export interface PortraitProps extends ExpandableImageProps {}

export const Portrait = ({ className, ...otherProps }: PortraitProps) => {
  return (
    <ExpandableImage
      className={formatClassName('portrait', className)}
      {...otherProps}
    />
  );
};

export default Portrait;
